export const TAGS = [
  { label: 'General', value: 'default' },
  { label: 'Salón', value: 'S' },
  { label: 'Delivery', value: 'D' },
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'E', value: 'E' },
  { label: 'F', value: 'F' },
];
