export type Order = {
  storeOrderId: string;
  locationName: string;
  status: string;
  cancelReason: string;
  price: number;
  phoneNumber: string;
  seen: boolean;
  couponDiscount: number;
  couponName: string;
  ticketUrl: string;
  cashDiscountAmount: number;
  cashDiscountPercentage: number;
  identificationName: string;
  identificationNameLabel: string;
  paymentDTO: {
    id: number;
    paymentMethod: string;
    approved: boolean;
    payer_last_name: string;
    payer_first_name: string;
    payer_email: string;
    status: string;
    external_reference: string;
    addToAccountText: string;
  };
  address: string;
  deliveryCost: string;
  fee: number;
  tip: number;
  id: string;
  elapsedTime: string;
  statusType: "PENDING" | "IN_PROGRESS" | "BAD" | "SUCCESS";
  sentDate: string;
  estimatedTime: string;
  additionalInfo: string;
  items: [];
};

export type User = {
  currentUser: boolean;
  deleteAllowed: boolean;
  editAllowed: boolean;
  password: string;
  roles: Set<string>;
  username: string;
};

// export const ROLES = [
//   { value: 'ROLE_ADMIN', label: 'Admin' },
//   { value: 'ROLE_USERS', label: 'Usuarios' },
//   { value: 'ROLE_ORDER_HISTORY', label: 'Historico' },
//   { value: 'ROLE_QR_GENERATION', label: 'Generación de QRs' },
//   { value: 'ROLE_CONFIGURATION', label: 'Configuración' },
//   { value: 'ROLE_MENU_EDITION', label: 'Menu' },
//   { value: 'ROLE_ORDERS', label: 'Ordenes' },
//   { value: 'ROLE_PLAN', label: 'Estado Del Plan' },
//   { value: 'ROLE_COUPON', label: 'Cupones' },
//   { value: 'ROLE_STORE_INFO', label: 'Info del Comercio' },
// ];

export type Configuration = {
  addToAccountEnabled: boolean,
  addToAccountText: string,
  allowedPwaThemes: string;
  ask_for_estimated_time: boolean;
  automatically_turn_off_service: boolean;
  automatically_turn_off_service_hour: string;
  cashEnabled: boolean;
  couponsEnabled: boolean;
  deliveryCost: string;
  cashDiscountEnabled: boolean;
  cashDiscountPercentage: number;
  delivery_configurable_from_backorder: boolean;
  delivery_enabled: boolean;
  displayItemImage: boolean;
  finished_orders_display_time: string;
  incomingOrderSoundEnabled: boolean;
  incomingOrderSoundMaxTime: string;
  incoming_order_notification_available: boolean;
  incoming_order_notification_email: string;
  incoming_order_notification_enabled: boolean;
  incoming_order_notification_sms_available: boolean;
  incoming_order_notification_sms_enabled: boolean;
  incoming_order_notification_sms_phone_number: string;
  mercadoPagoEnabled: boolean;
  mp_access_token: string;
  mp_client_id: string;
  mp_client_secret: string;
  mp_picture_url: string;
  notifyOrderCanceled: boolean;
  notifyOrderReady: boolean;
  notifyOrderRefunded: boolean;
  notifyOrderRejected: boolean;
  notifyOrderTaken: boolean;
  order_enabled: boolean;
  order_printing_button_enabled: boolean;
  point_of_contact_enabled: boolean;
  print_when_order_is_accepted: boolean;
  printing_available: boolean;
  pwaAdditionalInfoPlaceHolder: string;
  pwaAdditionalInfoText: string;
  pwaPersonalizationAllowed: boolean;
  take_away_enabled: boolean;
  opening_hours_activated: boolean;
  opening_hours_1_close: string;
  opening_hours_2_close: string;
  opening_hours_1_open: string;
  opening_hours_2_open: string;
  opening_days_activated: boolean;
  open_day_1: boolean;
  open_day_2: boolean;
  open_day_3: boolean;
  open_day_4: boolean;
  open_day_5: boolean;
  open_day_6: boolean;
  open_day_7: boolean;
  manualTipEnabled: boolean;
  percentageTipEnabled: boolean;
  fbPixelId: string;
  sms_notification_order_accepted_body: string;
  sms_notification_order_canceled_body: string;
  sms_notification_order_ready_body: string;
  sms_notification_order_refunded_body: string;
  sms_notification_order_rejected_body: string;
  deliveryZonesFeatureEnabled: boolean;
  deliveryZonesEnabled: boolean;
  deliveryZones: string;
  displayExternalReference: boolean;
  externalReferenceMandatory: boolean;
  externalReferenceLabel: string;
  importExportMenuEnabled: boolean;
  showWizard: boolean;
};

export type RenderingStatus =
  | "idle"
  | "loading"
  | "loaded"
  | "generatingQR"
  | "error"
  | "resolved";

export type UserInfo = {
  currentMonthTotalAmount: number;
  currentMonthTotalCount: number;
  currentYearTotalAmount: number;
  currentYearTotalCount: number;
  lastMonthTotalAmount: number;
  lastMonthTotalCount: number;
  lastYearTotalAmount: number;
  lastYearTotalCount: number;
  totalAmount: number;
  totalCount: number;
};

export type AlertMessage = {
  order: Order;
  message: string;
  value?: string;
  label?: string;
  key: string;
};

export type Stats = {
  menuViewCount: number;
  orderCount: number;
  parameterCount: number;
  rejectOrderCount: number;
};

export const COUPON_TYPES = [
  {
    label: "Fijo",
    key: "FIXED",
  },
  { label: "Porcentaje", key: "PERCENTAGE" },
];

export type CouponType = {
  creationTime: Date;
  id: number;
  locationId: string;
  locationName: string;
  maxDiscountAmount: number;
  maxUsages: number;
  minimumPurchaseAmount: number;
  name: string;
  posTag: string;
  sectionId: string;
  sectionName: string;
  type: "PERCENTAGE" | "FIXED";
  usages: number;
  validFrom: string;
  validTo: string;
  value: number;
  dayOfWeek: number;
};

export const daysOfTheWeek = [
  { label: "Todos" },
  { key: 1, label: "Lunes" },
  { key: 2, label: "Martes" },
  { key: 3, label: "Miércoles" },
  { key: 4, label: "Jueves" },
  { key: 5, label: "Viernes" },
  { key: 6, label: "Sábado" },
  { key: 7, label: "Domingo" },
];

export type StatusData = {
  currentOrderCount: number;
  orderLimit: number;
  percentageOfOrdersConsumed: number;
  remainingDays: number;
  remainingOrders: number;
  state:
    | "OK"
    | "ApplicationDisabled"
    | "OrdersLimitPeriodWarningReached"
    | "OutsideOpeningHours"
    | "StoreCloseToday"
    | "OrdersLimit1dReached"
    | "OrdersLimit7dReached"
    | "OrdersLimit30dReached"
    | "PeriodOrdersLimitReached"
    | "PeriodLimitReached";
};

export type LocationInfo = {
  stateV2: StatusData;
  child: boolean;
  creationTime: string;
  displayName: string;
  id: number;
  name: string;
  parent: boolean;
  state:
    | "OK"
    | "ApplicationDisabled"
    | "OrdersLimitPeriodWarningReached"
    | "OutsideOpeningHours"
    | "StoreCloseToday"
    | "OrdersLimit1dReached"
    | "OrdersLimit7dReached"
    | "OrdersLimit30dReached"
    | "PeriodOrdersLimitReached"
    | "PeriodLimitReached";
};

export type StoreStatus = {
  isClosed: boolean;
  message: string;
  extendedMessage: string;
};

export const STORE_STATUS = {
  OK: {
    isClosed: false,
    message: "Servicio Habilitado",
    extendedMessage: "",
  },
  ApplicationDisabled: {
    isClosed: true,
    message: "Servicio Deshabilitado",
    extendedMessage:
      "La aplicación se encuentra deshabilitada. No es posible recibir ordenes.",
  },
  OutsideOpeningHours: {
    isClosed: true,
    message: "Servicio Deshabilitado",
    extendedMessage:
      "Se encuentra fuera del horario de apertura. No es posible recibir ordenes.",
  },
  StoreCloseToday: {
    isClosed: true,
    message: "Servicio Deshabilitado",
    extendedMessage:
      "El comercio se encuentra cerrado el día de hoy. No es posible recibir ordenes.",
  },
  OrdersLimit1dReached: {
    isClosed: true,
    message: "Límite Alcanzado",
    extendedMessage:
      "El límite diario de ordenes ha sido alcanzado. No es posible recibir ordenes.",
  },
  OrdersLimit7dReached: {
    isClosed: true,
    message: "Límite Alcanzado",
    extendedMessage:
      "El límite semanal de ordenes ha sido alcanzado. No es posible recibir ordenes.",
  },
  OrdersLimit30dReached: {
    isClosed: true,
    message: "Límite Alcanzado",
    extendedMessage:
      "El límite mensual de ordenes ha sido alcanzado. No es posible recibir ordenes.",
  },
  PeriodOrdersLimitReached: {
    isClosed: true,
    message: "Límite Alcanzado",
    extendedMessage:
      "El límite de ordenes del período ha sido alcanzado. No es posible recibir ordenes.",
  },
  PeriodLimitReached: {
    isClosed: true,
    message: "Plan Vencido",
    extendedMessage:
      'El plan se encuentra vencido. No es posible recibir ordenes. Para activar el servicio, realice el pago en la sección "Estado del Plan"',
  },
  OrdersLimitPeriodWarningReached: {
    isClosed: false,
    message: "Servicio Habilitado",
    extendedMessage: "",
  },
};

export type Product = {
  description: string;
  hidden: boolean;
  id: number;
  imageLink: string;
  name: string;
  price: number;
  sectionId?: number;
  special?: boolean;
  stock?: number;
  subItemsCategories: SubCategory[];
};

export type NewProduct = Omit<Product, "id">;

export type CurrentPeriod = {
  currentOrders: number;
  expiration: Date;
  freePeriod: boolean;
  orderLimit: number;
  paid: boolean;
  periodFromDate: Date;
  periodToDate: Date;
  planName: string;
  planPrice: number;
  nextPeriod: Date;
};

export type StorePlan = {
  description: string;
  excludedFeatures: string;
  features: string;
  monthlyOrderLimit: number;
  name: string;
  posLimit: number;
  price: number;
  usersLimit: number;
};

export type PlanStatus = {
  currentPeriod: CurrentPeriod;
  nextPeriod: CurrentPeriod;
  storePlans: StorePlan[];
};

export type ConfirmationPlan = {
  amount: string;
  periodFrom: string;
  periodTo: string;
  updatePlanOptions: string;
  newPlan: string;
};

export type SubItem = {
  hidden: boolean;
  id: number;
  name: string;
  price: number;
  subItemId: number;
  quantity: number;
};

export type SubCategory = {
  hidden: boolean;
  id: number;
  maxSelection: number;
  minSelection: number;
  name: string;
  repeatSelection: boolean;
  chargeOnlyTheMostExpensive: boolean;
  selectionType: string;
  subItems: SubItem[];
};

export type OrderItem = {
  description: string;
  imageLink: string;
  itemAndSubItemsPrice: number;
  itemAndSubItemsPriceWithQuantity: number;
  itemId: number;
  itemPrice: number;
  name: string;
  quantity: number;
  section: number;
  stock: number;
  subItems: SubItem[];
};

export type Section = {
  hidden: boolean;
  id: number;
  items: Product[];
  name: string;
  locationTags: Set<string>;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export type DeliveryZone = {
  coordinates: Array<LatLng>;
  price: number;
};

export type DeliveryZones = {
  deliveryZones: Array<DeliveryZone>;
};

export enum AddressType {
  Valid = "Valid",
  NotValid = "NotValid",
}

export type AddressInfo = {
  type: AddressType;
  coordinates: LatLng;
  shortAddress: string;
  fullAddress: string;
}

export enum PaymentMethod {
  cash = "CASH",
  mercadoPago = "MERCADO_PAGO",
  addToAccount = "ADD_TO_ACCOUNT",
};
