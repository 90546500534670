import React from "react";
import { NavLink } from "react-router-dom";
import { Product } from "../types";

import {
  EyeIcon,
  EyeOffIcon,
  TrashIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";

export interface ProductProps {
  product: Product;
  id: number;
  index: number;
  removeProduct: (index: number) => void;
  changeVisibility: (id: number, value: boolean) => void;
}

export const ProductElement = ({
  product,
  index,
  changeVisibility,
  removeProduct,
}: ProductProps) => {
  return (
    <div
      key={product.id}
      className="w-full bg-white flex p-2 border rounded-lg justify-between my-1 border-gray-300"
    >
      <div className="flex items-center min-w-0">
        <svg
          className="mr-2 cursor-pointer hover:opacity-75 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
        </svg>
        <div className="overflow-ellipsis overflow-x-hidden whitespace-nowrap mr-2">
          {product.name}
        </div>
      </div>
      <div className="flex items-center">
        {product.price > 0 && <span className="pr-4">{product.price}$</span>}
        {product.hidden ? (
          <button
            title="Habilitar Producto"
            onClick={() => changeVisibility(product.id, false)}
          >
            <EyeOffIcon className="h-6 cursor-pointer hover:opacity-75 mr-2 text-red-600" />
          </button>
        ) : (
          <button
            title="Deshabilitar Producto"
            onClick={() => changeVisibility(product.id, true)}
          >
            <EyeIcon className="cursor-pointer hover:opacity-75 mr-2 h-6" />
          </button>
        )}
        <NavLink
          title="Editar Producto"
          to={`/menu/${product.sectionId}/${product.id}/customize`}
        >
          <PencilAltIcon className="cursor-pointer hover:opacity-75 mx-1 h-6" />
        </NavLink>
        <button title="Eliminar Producto" onClick={() => removeProduct(index)}>
          <TrashIcon className="cursor-pointer hover:opacity-75 mx-1 h-6" />
        </button>
      </div>
    </div>
  );
};
