import { alertService } from './alertService';
import { DeliveryZones } from '../types';
import { GoogleMapsUtils } from './googleMapsUtils';

export type EmbeddedMapProps = {
  mapRef: React.MutableRefObject<HTMLDivElement>;
  center: google.maps.LatLngLiteral;
};

export class GoogleMapsDrawingService {
  map?: google.maps.Map;

  marker?: google.maps.Marker;

  drawingManager?: google.maps.drawing.DrawingManager;

  infoWindow?: google.maps.InfoWindow;

  selectedShape?: google.maps.Polygon;

  private deliveryZoneChangedHandler = (
    shapePrices: Map<google.maps.Polygon, number>
  ) => {
    alertService.error(
      `La configuracion del mapa no es valida, no es posible actualizar::${shapePrices}`
    );
  };

  shapePrices: Map<google.maps.Polygon, number> = new Map();
  shapeLabels: Map<google.maps.Polygon, google.maps.Marker> = new Map();

  defaultPrice = 0;

  public updateDefaultPrice(newPrice: number) {
    if (newPrice >= 0) {
      this.defaultPrice = newPrice;
    }
  }

  public setDeliveryZoneChangeCallback(
    deliveryZonesChangedCallback: (map: DeliveryZones) => void
  ) {
    this.deliveryZoneChangedHandler = (
      shapePrices: Map<google.maps.Polygon, number>
    ) => {
      deliveryZonesChangedCallback(
        GoogleMapsUtils.convertToDeliveryZones(shapePrices)
      );
    };
  }

  public initializeMap({ mapRef, center }: EmbeddedMapProps): void {
    this.shapePrices.clear();
    this.shapeLabels.clear();
    this.map = GoogleMapsDrawingService.initializeGoogleMap(mapRef, center);
    this.marker = GoogleMapsDrawingService.initializeMarker(this.map, center);
    this.infoWindow = GoogleMapsDrawingService.initializeInfoWindow(this.map);
    this.initializeDrawingManager(this.map, this.deliveryZoneChangedHandler);
  }

  private static initializeGoogleMap(
    mapRef: React.MutableRefObject<HTMLDivElement>,
    center: google.maps.LatLngLiteral
  ) {
    const mapOptions = {
      center,
      zoom: 15,
      streetViewControl: false,
      mapTypeControl: false,
    };

    return new google.maps.Map(mapRef.current, mapOptions);
  }

  private static initializeMarker(
    map: google.maps.Map,
    center: google.maps.LatLngLiteral
  ) {
    return new google.maps.Marker({
      position: center,
      map,
    });
  }

  private static initializeInfoWindow(map: google.maps.Map) {
    const infoWindow = new google.maps.InfoWindow();
    google.maps.event.addListener(map, 'click', () => {
      infoWindow.close();
    });
    return infoWindow;
  }

  private initializeDrawingManager(
    map: google.maps.Map,
    deliveryZoneChangedHandler: (
      shapePrices: Map<google.maps.Polygon, number>
    ) => void
  ) {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        fillColor: '#AFFFD8',
        strokeColor: '#409A6D',
        fillOpacity: 0.3,
        strokeWeight: 2,
        clickable: true,
        editable: true,
        map,
      },
      map,
    });

    google.maps.event.addListener(
      this.drawingManager,
      'drawingmode_changed',
      () => {
        this.clearSelection();
      }
    );

    google.maps.event.addListener(
      this.drawingManager,
      'polygoncomplete',
      (polygon: google.maps.Polygon) => {
        this.switchToNonDrawingMode();
        this.addEventsForPolygon(polygon, deliveryZoneChangedHandler);
        
        this.setSelection(polygon);
      }
    );

    google.maps.event.addListener(
      this.drawingManager,
      'polygoncomplete',
      (polygon: google.maps.Polygon) => {
        if (polygon.getPath().getLength() === 2) {
          alertService.error('Zona de delivery invalida!');
          polygon.setMap(null);
          return;
        }
        this.shapePrices.set(polygon, this.defaultPrice);
        this.shapeLabels.set(polygon, GoogleMapsUtils.createMarkerFor(polygon, GoogleMapsDrawingService.asPrice(this.defaultPrice)));
        deliveryZoneChangedHandler(this.shapePrices);
      }
    );

    google.maps.event.addListener(
      this.drawingManager,
      'drawingmode_changed',
      () => {
        if (
          this.drawingManager?.getDrawingMode() ===
          google.maps.drawing.OverlayType.POLYGON &&
          this.shapePrices.size >= 5
        ) {
          this.switchToNonDrawingMode();
          alertService.error('No es posible añadir mas de 5 Zonas de Envio!');
        }
      }
    );
  }

  private switchToNonDrawingMode(): void {
    this.drawingManager?.setDrawingMode(null);
  }

  private addEventsForPolygon(
    polygon: google.maps.Polygon,
    deliveryZoneChangedHandler: (
      shapePrices: Map<google.maps.Polygon, number>
    ) => void
  ): void {
    google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
      deliveryZoneChangedHandler(this.shapePrices);
    });

    google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
      deliveryZoneChangedHandler(this.shapePrices);
    });

    google.maps.event.addListener(
      polygon,
      'click',
      (event: google.maps.PolyMouseEvent) => {
        this.setSelection(polygon);
        this.showCoordinatesOnInfoWindow(
          polygon,
          event,
          deliveryZoneChangedHandler
        );
      }
    );
  }

  public initializeDeliveryZones(deliveryZones: DeliveryZones): void {
    const zonesToDraw = deliveryZones.deliveryZones;

    if (
      zonesToDraw === undefined ||
      zonesToDraw.length === 0 ||
      this.shapePrices.size !== 0
    ) {
      return;
    }

    zonesToDraw.forEach((deliveryZone) => {
      const paths = GoogleMapsUtils.asGoogleMapsCoordinates(deliveryZone);

      const polygon = new google.maps.Polygon({
        paths,
        fillColor: '#AFFFD8',
        strokeColor: '#409A6D',
        fillOpacity: 0.3,
        strokeWeight: 2,
        clickable: true,
        editable: true,
        map: this.map,
      });
      this.shapePrices.set(polygon, deliveryZone.price);
      this.shapeLabels.set(polygon, GoogleMapsUtils.createMarkerFor(polygon, GoogleMapsDrawingService.asPrice(deliveryZone.price)));
      this.addEventsForPolygon(polygon, this.deliveryZoneChangedHandler);
    });
    this.clearSelection();
    this.switchToNonDrawingMode();
  }

  private static asPrice(price: number) : string {
    return `$${price}`;
  }

  private clearSelection(): void {
    this.selectedShape?.setEditable(false);
    this.selectedShape = undefined;
  }

  private setSelection(shape: google.maps.Polygon): void {
    this.clearSelection();
    this.selectedShape = shape;
    this.selectedShape.setEditable(true);
  }

  private deleteSelectedShape(
    deliveryZoneChangedHandler: (
      shapePrices: Map<google.maps.Polygon, number>
    ) => void
  ): void {
    if (!this.selectedShape) {
      return;
    }
    this.shapePrices.delete(this.selectedShape);
    this.shapeLabels.get(this.selectedShape)?.setMap(null);
    this.shapeLabels.delete(this.selectedShape);
    this.selectedShape.setMap(null);
    deliveryZoneChangedHandler(this.shapePrices);
  }

  private showCoordinatesOnInfoWindow(
    polygon: google.maps.Polygon,
    event: google.maps.PolyMouseEvent,
    deliveryZoneChangedHandler: (
      shapePrices: Map<google.maps.Polygon, number>
    ) => void
  ): void {
    const newContent = document.createElement('div');

    newContent.appendChild(document.createTextNode('Zona de envío: '));
    newContent.appendChild(document.createElement('br'));

    newContent.appendChild(
      document.createTextNode(
        `Precio actual: $`
      )
    );

    const input = document.createElement("input");
    input.setAttribute('type', 'number');
    input.setAttribute('min', '0');
    input.setAttribute('class', 'input');
    input.setAttribute('value', `${this.shapePrices.get(polygon)}`);

    input.onkeydown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.updatePrice(polygon, input.value, deliveryZoneChangedHandler);
        this.clearSelection();
        return;
      }

      if (event.key === 'Backspace' || event.key === 'Delete' ||
        event.key === 'ArrowUp' || event.key === 'ArrowDown' ||
        event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        return;
      }

      if (event.key === '0' ||
        event.key === '1' ||
        event.key === '2' ||
        event.key === '3' ||
        event.key === '4' ||
        event.key === '5' ||
        event.key === '6' ||
        event.key === '7' ||
        event.key === '8' ||
        event.key === '9') {
        return;
      }
      event.preventDefault();
    };

    input.onchange = () => {
      this.updatePrice(polygon, input.value, deliveryZoneChangedHandler);
    };

    newContent.appendChild(input);

    newContent.appendChild(document.createElement('br'));

    const button = document.createElement('button');

    button.innerHTML = 'Eliminar';
    button.addEventListener('click', () => {
      this.infoWindow?.close();
      this.deleteSelectedShape(deliveryZoneChangedHandler);
    });

    newContent.appendChild(button);

    this.infoWindow?.setContent(newContent);
    this.infoWindow?.setPosition(event.latLng);
    this.infoWindow?.open(this.map);
  }

  private updatePrice(polygon: google.maps.Polygon, newPrice: string, deliveryZoneChangedHandler: (
    shapePrices: Map<google.maps.Polygon, number>
  ) => void): void {
    let newValue = 0;
    if (newPrice !== '') {
      newValue = parseInt(newPrice, 10);
    }
    this.shapePrices.set(polygon, newValue);
    this.shapeLabels.get(polygon)?.setLabel(GoogleMapsDrawingService.asPrice(newValue));
    deliveryZoneChangedHandler(this.shapePrices);
  }
}
