import React, { useContext, useEffect, useState } from "react";
import apiService from "./../services/api";
import { Spinner } from "../components/Spinner";
import { PageTitle } from "../components/PageTitle";
import { ImgLoader } from "../components/ImgLoader";
import { Button } from "../components/Button";
import { EmbeddedDrawingMap } from "../components/EmbeddedDrawingMap";
import { Configuration, AddressInfo, AddressType } from "../types";
import Switch from "react-switch";
import { StoreContext } from "../services/store.context";
import { Tab } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/solid";

const tabs = [
  "HORARIOS",
  "NOTIFICACIONES",
  "ÓRDENES",
  "PROPINAS",
  "DELIVERY",
  "PAGOS",
  "ANALYTICS",
];

type Day = {
  key:
    | "open_day_1"
    | "open_day_2"
    | "open_day_3"
    | "open_day_4"
    | "open_day_5"
    | "open_day_6"
    | "open_day_7";
  label: string;
};

const daysOfTheWeek: Day[] = [
  { key: "open_day_1", label: "Lunes" },
  { key: "open_day_2", label: "Martes" },
  { key: "open_day_3", label: "Miércoles" },
  { key: "open_day_4", label: "Jueves" },
  { key: "open_day_5", label: "Viernes" },
  { key: "open_day_6", label: "Sábado" },
  { key: "open_day_7", label: "Domingo" },
];

const Config: React.FC = () => {
  const [locationConfig, setLocationConfig] = useState({} as Configuration);
  const [addressInfo, setAddressInfo] = useState({} as AddressInfo);
  const [status, setStatus] = useState("idle");
  const [statusService, setStatusService] = useState("idle");
  const [pictureUrl, setPictureUrl] = useState("");
  const [credentials, setCredentials] = useState("");
  const { updateOrderParameters } = useContext(StoreContext);

  const pictureUploaded = (url: string) => {
    setPictureUrl(url);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      setStatus("loading");

      const storeConfig = await apiService.getCall("store/parameter");
      setLocationConfig(storeConfig);

      if (storeConfig.deliveryZonesFeatureEnabled) {
        const storeAddress = await apiService.getCall("store/address");
        setAddressInfo(storeAddress);
      } else {
        const storeAddress = { type: AddressType.NotValid } as AddressInfo;
        setAddressInfo(storeAddress);
      }

      setStatus("resolved");
    };
    fetchConfig();
  }, []);

  const classNames = (...classes: string[]) =>
    classes.filter(Boolean).join(" ");

  const updateServiceStatus = async (orderEnabled: boolean) => {
    if (statusService === "loading") return;
    setStatusService("loading");
    await apiService.putCall(
      {
        key: "order_enabled",
        value: orderEnabled,
      },
      "store/parameter/update"
    );
    updateOrderParameters();
    setLocationConfig({
      ...locationConfig,
      order_enabled: orderEnabled,
    });
    setStatusService("iddle");
  };

  const saveConfig = async (showLoading = false) => {
    const {
      incoming_order_notification_available,
      incoming_order_notification_sms_available,
      printing_available,
      allowedPwaThemes,
      ...requestbody
    } = locationConfig;
    const response = await apiService.putCall(
      { ...requestbody, mp_picture_url: pictureUrl },
      "store/parameters/update"
    );
    if (!response.error) {
      updateOrderParameters();
    } else {
      // TODO: add error handling
    }
  };

  const validateCredentials = async () => {
    await saveConfig(false);
    try {
      await apiService.getCall("store/payment/credentials/verify", true);
      setCredentials("valid");
    } catch (error) {
      setCredentials("invalid");
    }
  };

  if (status === "idle" || status === "loading") {
    return (
      <div className="p-4">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <PageTitle title="Configuración del comercio" />
      <div>
        <form>
          <div className="section-border p-4 mb-4">
            <div className="w-auto flex items-start">
              <label className="block font-medium text-gray-700">
                Estado del servicio
                <p className="text-gray-600 text-xs">
                  Habilitar o deshabilitar el servicio temporalmente.
                </p>
              </label>
              <Switch
                offColor="#ff0000"
                onColor="#409a6d"
                onChange={updateServiceStatus}
                checked={locationConfig.order_enabled}
              />
            </div>
          </div>
          <div className="section-border p-4 my-4 relative">
            <Tab.Group>
              <Tab.List className="grid grid-cols-2 text-sm lg:flex lg:space-x-1 border-b border-gray-300">
                {tabs.map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2 text-sm leading-5 font-medium text-gray-600",
                        "focus:outline-none",
                        selected
                          ? "text-primary-300 border-b-2 border-primary-200"
                          : " hover:opacity-50"
                      )
                    }
                  >
                    {tab}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="my-4">
                <Tab.Panel className="my-8">
                  <div className="px-2 py-1 border mb-2 rounded-lg border-gray-300">
                    <div className="my-2 w-auto flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        checked={locationConfig.opening_hours_activated}
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            opening_hours_activated: event.target.checked,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Horarios de Apertura
                      </label>
                    </div>
                    {locationConfig.opening_hours_activated && (
                      <div>
                        <p className="text-gray-600 my-2 text-sm">
                          En caso de horario cortado indique ambos períodos
                        </p>
                        <div className="my-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Hora de Apertura
                          </label>
                          <input
                            className="input"
                            type="time"
                            value={locationConfig.opening_hours_1_open}
                            onChange={(event) => {
                              setLocationConfig({
                                ...locationConfig,
                                opening_hours_1_open: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="my-2 w-auto">
                          <label className="block text-sm font-medium text-gray-700">
                            Hora de Cierre
                          </label>
                          <input
                            className="input"
                            type="time"
                            value={locationConfig.opening_hours_1_close}
                            onChange={(event) => {
                              setLocationConfig({
                                ...locationConfig,
                                opening_hours_1_close: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="my-2 w-auto">
                          <label className="block text-sm font-medium text-gray-700">
                            Hora de Apertura
                          </label>
                          <input
                            className="input"
                            type="time"
                            value={locationConfig.opening_hours_2_open}
                            onChange={(event) => {
                              setLocationConfig({
                                ...locationConfig,
                                opening_hours_2_open: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="my-2 w-auto">
                          <label className="block text-sm font-medium text-gray-700">
                            Hora de Cierre
                          </label>
                          <input
                            className="input"
                            type="time"
                            value={locationConfig.opening_hours_2_close}
                            onChange={(event) => {
                              setLocationConfig({
                                ...locationConfig,
                                opening_hours_2_close: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* DAYS OF THE WEEK */}
                  <div className="px-2 py-1 border mb-2 rounded-lg border-gray-300">
                    <div className="my-2 w-auto flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        checked={locationConfig.opening_days_activated}
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            opening_days_activated: event.target.checked,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Definir días de la semana
                      </label>
                    </div>
                    {locationConfig.opening_days_activated && (
                      <div>
                        {daysOfTheWeek.map(({ label, key }) => (
                          <div
                            className="pl-2 text-gray-600 my-2 w-auto flex items-center"
                            key={key}
                          >
                            <input
                              className="input text-primary-300 mr-2"
                              type="checkbox"
                              checked={locationConfig[key]}
                              onChange={(event) =>
                                setLocationConfig({
                                  ...locationConfig,
                                  [key]: event.target.checked,
                                })
                              }
                            />
                            <label className="block text-sm font-medium text-gray-700">
                              {label}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Tab.Panel>
                <Tab.Panel className="my-8">
                  <h2 className="mb-3 text-gray-700 text-md">
                    Notifica a tu cliente por{" "}
                    <span className="font-semibold">Whatsapp</span> los cambios
                    de sus órdenes
                  </h2>
                  <div className="my-2 w-auto flex items-center">
                    <input
                      className="input text-primary-300 mr-2"
                      type="checkbox"
                      checked={locationConfig.notifyOrderTaken}
                      onChange={(event) =>
                        setLocationConfig({
                          ...locationConfig,
                          notifyOrderTaken: event.target.checked,
                        })
                      }
                    />

                    <label className="block text-sm font-medium text-gray-700">
                      Notificar cuando una orden es aceptada
                    </label>
                  </div>
                  <div className="my-2 w-auto flex items-center">
                    <input
                      className="input text-primary-300 mr-2"
                      type="checkbox"
                      checked={locationConfig.notifyOrderReady}
                      onChange={(event) =>
                        setLocationConfig({
                          ...locationConfig,
                          notifyOrderReady: event.target.checked,
                        })
                      }
                    />
                    <label className="block text-sm font-medium text-gray-700">
                      Notificar cuando una orden esta lista
                    </label>
                  </div>
                  <div className="my-2 w-auto flex items-center">
                    <input
                      className="input text-primary-300 mr-2"
                      type="checkbox"
                      checked={locationConfig.notifyOrderRejected}
                      onChange={(event) =>
                        setLocationConfig({
                          ...locationConfig,
                          notifyOrderRejected: event.target.checked,
                        })
                      }
                    />
                    <label className="block text-sm font-medium text-gray-700">
                      Notificar cuando una orden es rechazada
                    </label>
                  </div>
                  <div className="my-2 w-auto flex items-center">
                    <input
                      className="input text-primary-300 mr-2"
                      type="checkbox"
                      checked={locationConfig.notifyOrderCanceled}
                      onChange={(event) =>
                        setLocationConfig({
                          ...locationConfig,
                          notifyOrderCanceled: event.target.checked,
                        })
                      }
                    />
                    <label className="block text-sm font-medium text-gray-700">
                      Notificar cuando una orden es cancelada
                    </label>
                  </div>
                  <div className="my-2 w-auto flex items-center">
                    <input
                      className="input text-primary-300 mr-2"
                      type="checkbox"
                      checked={locationConfig.notifyOrderRefunded}
                      onChange={(event) =>
                        setLocationConfig({
                          ...locationConfig,
                          notifyOrderRefunded: event.target.checked,
                        })
                      }
                    />
                    <label className="block text-sm font-medium text-gray-700">
                      Notificar cuando una orden es devuelta
                    </label>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="my-8">
                  <div>
                    <div className="my-2 flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            ask_for_estimated_time: event.target.checked,
                          })
                        }
                        checked={locationConfig.ask_for_estimated_time}
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Solicitar tiempo estimado cuando una órden se acepta
                      </label>
                    </div>
                    <div className="my-2 flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            incoming_order_notification_enabled:
                              event.target.checked,
                          })
                        }
                        checked={
                          locationConfig.incoming_order_notification_enabled
                        }
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Habilitar notificaciones de nuevas órdenes por email
                      </label>
                    </div>
                    {locationConfig.incoming_order_notification_enabled && (
                      <div className="mt-2 mb-4">
                        <label className="block text-sm font-medium text-gray-700 flex-shrink-0">
                          Email para notificación
                        </label>
                        <input
                          className="input"
                          type="email"
                          onChange={(event) =>
                            setLocationConfig({
                              ...locationConfig,
                              incoming_order_notification_email:
                                event.target.value,
                            })
                          }
                          value={
                            locationConfig.incoming_order_notification_email
                          }
                        />
                      </div>
                    )}
                    <div className="my-2 flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            incomingOrderSoundEnabled: event.target.checked,
                          })
                        }
                        checked={locationConfig.incomingOrderSoundEnabled}
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Habilitar sonido al recibir una órden
                      </label>
                    </div>

                    {locationConfig.incomingOrderSoundEnabled && (
                      <div className="mt-2 mb-4">
                        <label className="block text-sm font-medium text-gray-700 flex-shrink-0">
                          Duración del sonido
                        </label>
                        <input
                          className="input"
                          type="number"
                          onChange={(event) =>
                            setLocationConfig({
                              ...locationConfig,
                              incomingOrderSoundMaxTime: event.target.value,
                            })
                          }
                          value={locationConfig.incomingOrderSoundMaxTime}
                        />
                      </div>
                    )}
                    {locationConfig.incoming_order_notification_sms_available && (
                      <div className="my-2 flex items-center">
                        <input
                          className="input text-primary-300 mr-2"
                          type="checkbox"
                          onChange={(event) =>
                            setLocationConfig({
                              ...locationConfig,
                              incoming_order_notification_sms_enabled:
                                event.target.checked,
                            })
                          }
                          checked={
                            locationConfig.incoming_order_notification_sms_enabled
                          }
                        />
                        <label className="block text-sm font-medium text-gray-700">
                          Habilitar notificaciones de nuevas órdenes por SMS
                        </label>
                      </div>
                    )}
                    {locationConfig.incoming_order_notification_sms_available &&
                      locationConfig.incoming_order_notification_sms_enabled && (
                        <div className="my-2">
                          <label className="block text-sm font-medium text-gray-700 flex-shrink-0">
                            Teléfono para recibir los SMS
                          </label>
                          <input
                            className="input"
                            type="text"
                            onChange={(event) =>
                              setLocationConfig({
                                ...locationConfig,
                                incoming_order_notification_sms_phone_number:
                                  event.target.value,
                              })
                            }
                            value={
                              locationConfig.incoming_order_notification_sms_phone_number
                            }
                          />
                        </div>
                      )}

                    {locationConfig.printing_available && (
                      <div>
                        <div className="my-2 w-auto flex items-center">
                          <input
                            className="input text-primary-300 mr-2"
                            type="checkbox"
                            checked={
                              locationConfig.order_printing_button_enabled
                            }
                            onChange={(event) =>
                              setLocationConfig({
                                ...locationConfig,
                                order_printing_button_enabled:
                                  event.target.checked,
                              })
                            }
                          />
                          <label className="block text-sm font-medium text-gray-700">
                            Habilitar botón para imprimir órdenes
                          </label>
                        </div>
                        <div className="my-2 w-auto flex items-center">
                          <input
                            className="input text-primary-300 mr-2"
                            type="checkbox"
                            checked={
                              locationConfig.print_when_order_is_accepted
                            }
                            onChange={(event) =>
                              setLocationConfig({
                                ...locationConfig,
                                print_when_order_is_accepted:
                                  event.target.checked,
                              })
                            }
                          />
                          <label className="block text-sm font-medium text-gray-700">
                            Imprimir cuando una orden es aceptada
                          </label>
                        </div>
                        <div className="text-gray-700 text-sm flex items-center bg-gray-200 rounded-lg p-2 max-w-fit">
                          <InformationCircleIcon className="h-6 mr-2 text-gray-600" />
                          <span className="flex-shrink-0 pr-1">
                            Encuentra más información sobre como configurar la
                            impresora
                          </span>
                          <a
                            className="text-primary-300 hover:underline hover:opacity-70 pr-1 font-semibold"
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://docs.google.com/document/d/e/2PACX-1vQ0MNrB4WmPdYPKgK22SxAmsEIE06RNLTkhnhFaa-b2lbfrdPYL-lw0eYqcZUUfceapzDAjz8z1i1yR/pub"
                          >
                            aquí
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </Tab.Panel>
                <Tab.Panel className="my-8">
                  <div>
                    <div className="my-2 w-auto flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        checked={locationConfig.percentageTipEnabled}
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            percentageTipEnabled: event.target.checked,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Activar propina en formato de porcentajes
                      </label>
                    </div>
                    <div className="my-2 w-auto flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        checked={locationConfig.manualTipEnabled}
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            manualTipEnabled: event.target.checked,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Activar propina en formato libre
                      </label>
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="my-8">
                  <div>
                    <div className="my-2">
                      <label className="block text-sm font-medium text-gray-700 flex-shrink-0">
                        Costo de de los envíos en $
                      </label>
                      <input
                        className="input"
                        type="number"
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            deliveryCost: event.target.value,
                          })
                        }
                        value={locationConfig.deliveryCost}
                      />
                    </div>
                    {locationConfig.deliveryZonesFeatureEnabled && (
                      <div>
                        <div className="my-2 w-auto flex items-center">
                          <input
                            className="input text-primary-300 mr-2"
                            type="checkbox"
                            disabled={addressInfo.type !== AddressType.Valid}
                            checked={locationConfig.deliveryZonesEnabled}
                            onChange={(event) =>
                              setLocationConfig({
                                ...locationConfig,
                                deliveryZonesEnabled: event.target.checked,
                              })
                            }
                          />
                          <label className="block text-sm font-medium text-gray-700">
                            Activar Zona de envíos
                          </label>
                        </div>
                        {addressInfo.type !== AddressType.Valid && (
                          <p className="mb-3 text-gray-600 text-sm">
                            En la sección de Comercio ingrese Dirección y Ciudad
                            válidas para poder usar esta funcionalidad.
                          </p>
                        )}
                        {locationConfig.deliveryZonesEnabled && (
                          <div>
                            <EmbeddedDrawingMap
                              className="w-full h-96"
                              center={addressInfo.coordinates}
                              defaultPrice={parseInt(
                                locationConfig.deliveryCost,
                                10
                              )}
                              deliveryZones={JSON.parse(
                                locationConfig.deliveryZones
                              )}
                              deliveryZonesChangedCallback={(event) => {
                                setLocationConfig({
                                  ...locationConfig,
                                  deliveryZones: JSON.stringify(event),
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Tab.Panel>
                <Tab.Panel className="my-8">
                  <div className="px-2 py-2 border mb-2 rounded-lg border-gray-300">
                    <div className="mt-2 w-auto flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        checked={locationConfig.displayExternalReference}
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            displayExternalReference: event.target.checked,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Habilitar campo de confirmación extra
                      </label>
                    </div>
                    <p className="mb-3 text-gray-600 text-sm">
                      Este campo será visible al momento previo a enviar el
                      pedido. Ideal para requerir información extra del
                      consumidor.
                    </p>
                    {locationConfig.displayExternalReference && (
                      <div>
                        <div className="my-2">
                          <label className="block text-sm font-medium text-gray-700 flex-shrink-0">
                            Título del campo extra
                          </label>
                          <input
                            className="input w-64"
                            type="text"
                            placeholder="Título del campo extra"
                            value={locationConfig.externalReferenceLabel}
                            onChange={(event) => {
                              setLocationConfig({
                                ...locationConfig,
                                externalReferenceLabel: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="my-2 w-auto flex items-center">
                          <input
                            className="input text-primary-300 mr-2"
                            type="checkbox"
                            checked={locationConfig.externalReferenceMandatory}
                            onChange={(event) =>
                              setLocationConfig({
                                ...locationConfig,
                                externalReferenceMandatory:
                                  event.target.checked,
                              })
                            }
                          />
                          <label className="block text-sm font-medium text-gray-700">
                            Campo obligatorio
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="px-2 py-2 border mb-2 rounded-lg border-gray-300">
                    <div className="my-2 w-auto flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        checked={locationConfig.cashEnabled}
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            cashEnabled: event.target.checked,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Habilitar pagos en efectivo
                      </label>
                    </div>
                  </div>
                  <div className="px-2 py-2 border mb-2 rounded-lg border-gray-300">
                    <div className="my-2 w-auto flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        checked={locationConfig.cashDiscountEnabled}
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            cashDiscountEnabled: event.target.checked,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Habilitar descuento en pagos en efectivo
                      </label>
                    </div>
                    {locationConfig.cashDiscountEnabled && (
                      <div>
                        <div className="my-2 w-auto">
                          <label className="block text-sm font-medium text-gray-700">
                            Descuento %
                          </label>
                          <input
                            className="input"
                            type="number"
                            min={0}
                            max={100}
                            value={locationConfig.cashDiscountPercentage}
                            onChange={(event) => {
                              setLocationConfig({
                                ...locationConfig,
                                cashDiscountPercentage: parseInt(
                                  event.target.value,
                                  10
                                ),
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="px-2 py-2 border mb-2 rounded-lg border-gray-300">
                    <div className="my-2 w-auto flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        checked={locationConfig.mercadoPagoEnabled}
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            mercadoPagoEnabled: event.target.checked,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Habilitar pagos a traves de Mercado Pago
                      </label>
                    </div>
                    {locationConfig.mercadoPagoEnabled && (
                      <div>
                        <div className="text-xl mt-4 text-gray-700">
                          Credenciales de Mercado Pago
                        </div>
                        <div>
                          <div className="my-2">
                            <label className="block text-sm font-medium text-gray-700 flex-shrink-0">
                              Access Token (Obtegalo&nbsp;
                              <a
                                className="text-primary-300 hover:underline"
                                href="https://www.mercadopago.com/mla/account/credentials"
                              >
                                aquí
                              </a>
                              )
                              {credentials === "invalid" && (
                                <span className="text-red-600">
                                  &nbsp; - Credenciales Inválidas!
                                </span>
                              )}
                              {credentials === "valid" && (
                                <span className="text-green-600">
                                  &nbsp;- Credenciales Válidas!
                                </span>
                              )}
                            </label>
                            <input
                              className="input w-64"
                              type="text"
                              onChange={(event) =>
                                setLocationConfig({
                                  ...locationConfig,
                                  mp_access_token: event.target.value,
                                })
                              }
                              value={locationConfig.mp_access_token}
                            />
                          </div>
                          <div className="my-4">
                            <button
                              type="button"
                              className="btn text-xs bg-blue-500"
                              disabled={status === "validating"}
                              onClick={validateCredentials}
                            >
                              Validar credenciales
                            </button>
                          </div>
                        </div>
                        <div className="mb-2">
                          <div className="text-xl my-4 text-gray-700">
                            Imágen en MercadoPago (opcional)
                          </div>
                          <div className="w-48">
                            <ImgLoader
                              serverImg={locationConfig.mp_picture_url}
                              pictureUploaded={pictureUploaded}
                              pictureDeleted={() =>
                                setLocationConfig({
                                  ...locationConfig,
                                  mp_picture_url: "",
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}                    
                  </div>
                  <div className="px-2 py-2 border mb-2 rounded-lg border-gray-300">
                    <div className="my-2 w-auto flex items-center">
                      <input
                        className="input text-primary-300 mr-2"
                        type="checkbox"
                        checked={locationConfig.addToAccountEnabled}
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            addToAccountEnabled: event.target.checked,
                          })
                        }
                      />
                      <label className="block text-sm font-medium text-gray-700">
                        Habilitar método de pago personalizable
                      </label>
                    </div>
                    {locationConfig.addToAccountEnabled && (
                      <div>
                        <div className="my-2">
                          <label className="block text-sm font-medium text-gray-700 flex-shrink-0">
                            Texto a mostrar al usuario
                          </label>
                          <input
                            className="input w-64"
                            type="text"
                            onChange={(event) =>
                              setLocationConfig({
                                ...locationConfig,
                                addToAccountText: event.target.value,
                              })
                            }
                            value={locationConfig.addToAccountText}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Tab.Panel>
                <Tab.Panel className="my-8">
                  <div>
                    <div className="my-2">
                      <label className="block text-sm font-medium text-gray-700 flex-shrink-0">
                        Facebook Pixel ID
                      </label>
                      <input
                        className="input"
                        type="text"
                        onChange={(event) =>
                          setLocationConfig({
                            ...locationConfig,
                            fbPixelId: event.target.value,
                          })
                        }
                        value={locationConfig.fbPixelId}
                      />
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
              <div className="md:flex md:items-center my-3">
                <Button
                  text="Guardar Cambios"
                  onClick={() => saveConfig(true)}
                />
              </div>
              <p className="text-gray-600 text-sm">
                Recuerde que para hacer efectivas las actualizaciones es
                necesario guardar los cambios realizados.
              </p>
            </Tab.Group>
          </div>
        </form>
      </div>
    </>
  );
};

export default Config;
