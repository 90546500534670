import React, { useContext, useEffect, useRef, useState } from "react";
import apiService from "../services/api";
import { Modal } from "../components/Modal";
import { OrderDetail } from "../components/OrderDetail";
import { Spinner } from "../components/Spinner";
import { PageTitle } from "../components/PageTitle";
import { Order } from "../types";
import { useReactToPrint } from "react-to-print";
import { StoreContext } from "../services/store.context";
import { subDays, addDays, format, setHours } from "date-fns";
import { InformationCircleIcon } from "@heroicons/react/outline";

const statusClass: { [key: string]: { bg: string; text: string } } = {
  SUCCESS: { bg: "green-200", text: "green-500" },
  BAD: { bg: "red-200", text: "red-500" },
  IN_PROGRESS: { bg: "orange-200", text: "orange-500" },
  PENDING: { bg: "orange-200", text: "orange-500" },
};

const Historical: React.FC = () => {
  const { orderParameters } = useContext(StoreContext);
  const [historicalInfo, setHistoricalInfo] = useState([] as Order[]);
  const [loading, setLoading] = useState(false);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [orderSelected, setOrderSelected] = useState({} as Order);
  const componentRef = useRef();

  const handleDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value.replaceAll("-", "/"));
    setDateSelected(date);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
  });

  const openInfoModal = (order: Order) => {
    setOrderSelected(order);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const from = setHours(dateSelected, 0).getTime();
      const to = setHours(dateSelected, 24).getTime();
      const response = await apiService.getCall(`order/history/${from}/${to}`);
      setHistoricalInfo(response);
      setLoading(false);
    };
    fetchData();
  }, [dateSelected]);

  return (
    <>
      {orderSelected.id && (
        <Modal
          closeModal={() => setOrderSelected({} as Order)}
          isOpen={!!orderSelected.id}
          title="Detalle de la órden"
        >
          <div>
            <OrderDetail order={orderSelected} reference={componentRef}>
              <div className="flex mt-2 mb-2">
                {orderParameters.order_printing_button_enabled && (
                  <button
                    type="button"
                    onClick={handlePrint}
                    className="rounded font-semibold text-sm p-2 bg-blue-500 hover:opacity-75 text-white shadow-md"
                  >
                    IMPRIMIR
                  </button>
                )}
              </div>
            </OrderDetail>
          </div>
        </Modal>
      )}
      <div className="">
        <PageTitle title="Listado de Ordenes" />
        <p className="mb-4 text-gray-600 text-sm">
          En esta sección es posible visualizar el historial de órdenes ya
          procesadas.
        </p>
        <div className="flex items-center my-3 space-x-4">
          <input
            className="input"
            type="date"
            onChange={handleDate}
            value={format(dateSelected, "yyyy-MM-dd")}
          />
          <div className="flex rounded-lg shadow bg-white mr-4 cursor-pointer">
            <button
              className="flex items-center p-2 border-r border-gray-500 hover:opacity-50"
              onClick={() => setDateSelected(subDays(dateSelected, 1))}
            >
              <svg
                className="fill-current h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 26 26"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline fill="none" points="15 18 9 12 15 6"></polyline>
              </svg>
            </button>
            <button
              className="flex items-center p-2 hover:opacity-50"
              onClick={() => setDateSelected(addDays(dateSelected, 1))}
            >
              <svg
                className="fill-current h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline fill="none" points="9 18 15 12 9 6"></polyline>
              </svg>
            </button>
          </div>
        </div>

        <div className="mb-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="border-collapse table-auto w-full whitespace-no-wrap relative divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  #Orden
                </th>
                <th className="hidden md:table-cell whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Punto de venta
                </th>
                <th className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Estado
                </th>
                <th className="hidden md:table-cell whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Total
                </th>
                <th className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900">
                  <span className="sr-only">Acciones</span>
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {loading && (
                <tr className="text-center">
                  <td colSpan={5} className="p-2">
                    <Spinner />
                  </td>
                </tr>
              )}
              {!loading && historicalInfo.length === 0 && (
                <tr className="text-center">
                  <td colSpan={5} className="p-8">
                    No existen ordenes realizadas en esta fecha
                  </td>
                </tr>
              )}
              {!loading &&
                historicalInfo.map((order: Order) => (
                  <tr className="" key={order.storeOrderId}>
                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-600 sm:pl-6">
                      #{order.storeOrderId}
                    </td>
                    <td className="hidden md:table-cell whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                      {order.locationName}
                    </td>
                    <td
                      className={`whitespace-nowrap px-2 py-2 text-sm text-gray-900 `}
                    >
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${statusClass[order.statusType].bg} text-${statusClass[order.statusType].text}`}>{order.status}</span>
                    </td>
                    <td className="hidden md:table-cell whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                      {order.price}$
                    </td>
                    <td className="relative flex justify-end whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <InformationCircleIcon
                        className="h-6 cursor-pointer hover:opacity-70"
                        onClick={() => openInfoModal(order)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Historical;
