import { DeliveryZone, DeliveryZones, LatLng } from '../types';

export class GoogleMapsUtils {
  public static convertToDeliveryZones(
    polygons: Map<google.maps.Polygon, number>
  ): DeliveryZones {
    const zones = new Array<DeliveryZone>();

    polygons.forEach((value, key) => {
      const coordinates = key
        .getPath()
        .getArray()
        .map((latLng) => {
          return this.asLatLng(latLng);
        });

      const zone: DeliveryZone = {
        coordinates,
        price: value,
      };
      zones.push(zone);
    });

    const deliveryZones: DeliveryZones = {
      deliveryZones: zones,
    };

    return deliveryZones;
  }

  public static asLatLng(gmapCoordinate: google.maps.LatLng): LatLng {
    const coordinate: LatLng = {
      lat: gmapCoordinate.lat(),
      lng: gmapCoordinate.lng(),
    };
    return coordinate;
  }

  public static asGoogleMapsCoordinates(
    deliveryZone: DeliveryZone
  ): google.maps.MVCArray {
    const coordinates = new google.maps.MVCArray();
    deliveryZone.coordinates.forEach((coordinate) => {
      const latLng = new google.maps.LatLng(coordinate);
      coordinates.push(latLng);
    });
    return coordinates;
  }

  public static getTopPoint(
    polygon: google.maps.Polygon
  ): google.maps.LatLng {
    var bounds = new google.maps.LatLngBounds();
    polygon.getPath().forEach((coordinate) => {
      bounds.extend(coordinate);
    });

    return bounds.getCenter();
  }

  public static createMarkerFor(polygon: google.maps.Polygon, label: string) : google.maps.Marker{
    return new google.maps.Marker({
      position: GoogleMapsUtils.getTopPoint(polygon),
      label: {
        text: label,
         color: '#4A5568',
      },
      icon: {
        url: "https://maps.google.com/mapfiles/ms/icons/green.png",
        labelOrigin: new google.maps.Point(-16, 16),
      },
      map: polygon.getMap(),
    });
  }
}

const googleMapsUtils = new GoogleMapsUtils();
export default googleMapsUtils;
