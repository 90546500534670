import React from 'react';

type PageTitleProps = {
  title: string;
};

export const PageTitle: React.FC<PageTitleProps> = ({ title, children }) => {
  return (
    <div className="flex items-center justify-between mb-2">
      <h1 className="text-xl tracking-wide font-semibold uppercase">{title}</h1>
      {children && children}
    </div>
  );
};
