import React, { useState, useEffect, useContext } from "react";
import apiService from "../services/api";
import { Modal } from "../components/Modal";
import { CouponForm } from "../components/CouponForm";
import { Spinner } from "../components/Spinner";
import { PageTitle } from "../components/PageTitle";
import { Button } from "../components/Button";
import { PopUp, PopUphandler } from "../components/PopUp";
import { CouponType, daysOfTheWeek, Section } from "../types";
import { alertService } from "../services/alertService";
import Switch from "react-switch";
import { StoreContext } from "../services/store.context";
import {
  PencilAltIcon,
  InformationCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";

const Coupon = () => {
  const { orderParameters, updateOrderParameters } = useContext(StoreContext);
  const [listCodes, setListCodes] = useState([] as CouponType[]);
  const [sections, setSections] = useState(
    [] as { id: number; name: string }[]
  );
  const [status, setStatus] = useState("idle");
  const [confirmationPopUp, setConfirmationPopUp] = useState({} as PopUphandler);
  const [showModal, setShowModal] = useState({
    show: false,
    coupon: {} as CouponType,
  });
  const [showInfoModal, setShowInfoModal] = useState({
    show: false,
    info: {} as CouponType,
  });
  const types = {
    PERCENTAGE: { label: "porcentaje", symbol: "%" },
    FIXED: { label: "fijo", symbol: "$" },
  };

  const getDayOftheWeek = (index: number) => {
    const indexOfDay = daysOfTheWeek.findIndex((day) => index === day.key);
    if (indexOfDay !== -1) {
      return daysOfTheWeek[index].label;
    }
    return "Todos";
  };

  const fetchData = async () => {
    setStatus("loading");
    const couponList = apiService.getCall("coupon/list");
    const menu = apiService.getCall("menu");
    Promise.all([couponList, menu])
      .then(
        ([couponData, menuData]) => {
          setListCodes(couponData);
          setSections(
            menuData.sections.map((sec: Section) => ({
              name: sec.name,
              id: sec.id,
            }))
          );
          return setStatus("resolved");
        },
        () => {
          setStatus("errorData");
        }
      )
      .catch(() => {
        setStatus("errorData");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateCouponService = async (orderEnabled: boolean) => {
    if (status === "loading") return;
    // setStatusService('loading');
    await apiService.putCall(
      {
        key: "couponsEnabled",
        value: orderEnabled,
      },
      "store/parameter/update"
    );
    updateOrderParameters();
  };

  const closeModal = () => {
    setShowModal({ show: false, coupon: {} as CouponType });
  };

  const closeModalInfo = () => {
    setShowInfoModal({ show: false, info: {} as CouponType });
  };

  const removeCoupon = (id: number) => {
    setConfirmationPopUp({
      handler: async (_event, confirmation: boolean) => {
        if (confirmation) {
          const response = await apiService.deleteCall(`/coupon/remove/${id}`);
          if (!response.error) {
            fetchData();
          }
        }
        setConfirmationPopUp({} as PopUphandler);
      },
      message: "Estas seguro que desea eliminar este cupón?",
    });
  };

  const saveNewCoupon = async (newCoupon: CouponType) => {
    let response;
    const data = {
      ...newCoupon,
      validFrom:
        newCoupon.validFrom && new Date(newCoupon.validFrom).toISOString(),
      validTo: newCoupon.validTo && new Date(newCoupon.validTo).toISOString(),
    };
    try {
      if (showModal.coupon.id) {
        response = await apiService.putCall([data], "/coupon/update");
      } else {
        response = await apiService.putCall(data, "coupon/create");
      }
      if (!response.error) {
        fetchData();
        setShowModal({ show: false, coupon: {} as CouponType });
      } else {
        alertService.error(
          "Se ha producido un error, revise la información e intente nuevamente"
        );
      }
    } catch (error) {
      alertService.error(
        "Se ha producido un error, revise la información e intente nuevamente"
      );
    }
  };

  if (status === "idle" || status === "loading") {
    return (
      <div className="p-3">
        <Spinner />
      </div>
    );
  }

  if (status === "errorData") {
    return (
      <div>
        <PageTitle title="Cupones" />
        <div className="section-border p-4">
          <div className="text-gray-600">
            Error al cargar la página, por favor revise la información e intente
            nuevamente.
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal
        closeModal={() => closeModal()}
        size="md"
        isOpen={showModal.show}
        title="Agregar Nuevo Cupón"
      >
        <CouponForm
          sections={sections}
          saveCoupon={saveNewCoupon}
          couponProp={showModal.coupon as CouponType}
        />
      </Modal>
      {confirmationPopUp.message && (
        <PopUp
          closePopUp={confirmationPopUp.handler}
          message={confirmationPopUp.message}
        />
      )}
      {showInfoModal.show && (
        <Modal
          closeModal={() => closeModalInfo()}
          size="3xl"
          isOpen={showInfoModal.show}
          title="Detalles del Cupón"
        >
          <div className="mb-2 flex items-center">
            <label className="block tracking-wide text-gray-700 font-bold mr-2">
              Código
            </label>
            <div className="text-gray-600">{showInfoModal.info.name}</div>
          </div>
          <div className="mb-2 flex items-center">
            <label className="block tracking-wide text-gray-700 font-bold mr-2">
              Tipo de descuento
            </label>
            <div className="text-gray-600">
              {types[showInfoModal.info.type].label}
            </div>
          </div>
          <div className="mb-2 flex items-center">
            <label className="block tracking-wide text-gray-700 font-bold mr-2">
              Descuento
            </label>
            <div className="text-gray-600">
              {types[showInfoModal.info.type].symbol}
              {showInfoModal.info.value}
            </div>
          </div>
          <div className="mb-2 flex items-center">
            <label className="block tracking-wide text-gray-700 font-bold mr-2">
              Día de la semana
            </label>
            <div className="text-gray-600">
              {getDayOftheWeek(showInfoModal.info.dayOfWeek)}
            </div>
          </div>
          {showInfoModal.info.maxDiscountAmount && (
            <div className="mb-2 flex items-center">
              <label className="block tracking-wide text-gray-700 font-bold mr-2">
                Máximo valor de descuento
              </label>
              <div className="text-gray-600">
                ${showInfoModal.info.maxDiscountAmount}
              </div>
            </div>
          )}

          <div className="mb-2 flex items-center">
            <label className="block tracking-wide text-gray-700 font-bold mr-2">
              Sección
            </label>
            <div className="text-gray-600">
              {showInfoModal.info.sectionName
                ? showInfoModal.info.sectionName
                : "Todas"}
            </div>
          </div>
          {showInfoModal.info.posTag && (
            <div className="mb-2 flex items-center">
              <label className="block tracking-wide text-gray-700 font-bold mr-2">
                Grupo
              </label>
              <div className="text-gray-600">{showInfoModal.info.posTag}</div>
            </div>
          )}
          {showInfoModal.info.maxUsages && (
            <div className="mb-2 flex items-center">
              <label className="block tracking-wide text-gray-700 font-bold mr-2">
                Máxima cantidad de usos
              </label>
              <div className="text-gray-600">
                {showInfoModal.info.maxUsages}
              </div>
            </div>
          )}
          {showInfoModal.info.minimumPurchaseAmount && (
            <div className="mb-2 flex items-center">
              <label className="block tracking-wide text-gray-700 font-bold mr-2">
                Mínimo valor necesario
              </label>
              <div className="text-gray-600">
                ${showInfoModal.info.minimumPurchaseAmount}
              </div>
            </div>
          )}
          <div className="mb-2 flex items-center">
            <label className="block tracking-wide text-gray-700 font-bold mr-2">
              Cantidad de usos
            </label>
            <div className="text-gray-600">{showInfoModal.info.usages}</div>
          </div>
          {showInfoModal.info.validFrom && (
            <div className="mb-2 flex items-center">
              <label className="block tracking-wide text-gray-700 font-bold mr-2">
                Válido desde
              </label>
              <div className="text-gray-600">
                {new Date(showInfoModal.info.validFrom).toDateString()}
              </div>
            </div>
          )}
          {showInfoModal.info.validTo && (
            <div className="mb-2 flex items-center">
              <label className="block tracking-wide text-gray-700 font-bold mr-2">
                Válido hasta
              </label>
              <div className="text-gray-600">
                {new Date(showInfoModal.info.validTo).toDateString()}
              </div>
            </div>
          )}
        </Modal>
      )}
      <PageTitle title="Cupones" />
      <div className="items-center flex justify-between mb-2">
        <div className="flex items-start">
          <label className="block pr-4">
            Estado del servicio
            <div className="block text-sm font-medium text-gray-700">
              Habilitar o deshabilitar temporalmente.
            </div>
          </label>
          <Switch
            offColor="#ff0000"
            onColor="#409a6d"
            onChange={updateCouponService}
            checked={orderParameters.couponsEnabled}
          />
        </div>
        <Button
          type="secondary"
          text="AGREGAR CUPÓN"
          onClick={() =>
            setShowModal({
              show: true,
              coupon: { type: "FIXED" } as CouponType,
            })
          }
        />
      </div>
      <div className="mb-4 text-gray-600 text-sm">
        En esta sección puedes crear tus propios cupones de descuento en función
        del tipo de descuento, duración, cantidad de usos, punto de venta y
        categoría.
      </div>
      <div className="mb-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className="border-collapse table-auto w-full whitespace-no-wrap relative divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Cupón
              </th>
              <th className="hidden md:table-cell whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                Valor
              </th>
              <th className="hidden md:table-cell whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                Desde
              </th>
              <th className="hidden md:table-cell whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                Hasta
              </th>
              <th className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900">
                <span className="sr-only">Acciones</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-gray-700">
            {listCodes.map((coupon: CouponType) => (
              <tr className="" key={coupon.id}>
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-600 sm:pl-6">
                  {coupon.name}
                </td>
                <td className="hidden md:table-cell whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                  {coupon.value}
                  {types[coupon.type].symbol}
                </td>
                <td className="hidden md:table-cell whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  {coupon.validFrom
                    ? new Date(coupon.validFrom).toLocaleDateString()
                    : "-"}
                </td>
                <td className="hidden md:table-cell whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  {coupon.validTo
                    ? new Date(coupon.validTo).toLocaleDateString()
                    : "-"}
                </td>
                <td className="relative flex space-x-2 items-center justify-end whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <PencilAltIcon
                    className="h-6 cursor-pointer hover:opacity-70"
                    onClick={() =>
                      setShowModal({
                        show: true,
                        coupon: {
                          ...coupon,
                          validFrom:
                            coupon.validFrom && coupon.validFrom.split("T")[0],
                          validTo:
                            coupon.validTo && coupon.validTo.split("T")[0],
                        },
                      })
                    }
                  />
                  <InformationCircleIcon
                    className="h-6 cursor-pointer hover:opacity-70"
                    onClick={() =>
                      setShowInfoModal({ show: true, info: coupon })
                    }
                  />
                  <TrashIcon
                    className="h-6 cursor-pointer hover:opacity-70"
                    onClick={(_) => removeCoupon(coupon.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Coupon;
