import React, { useState, createRef } from "react";
import apiService, { FetchService } from "./../services/api";
import { Spinner } from "./Spinner";
import Resizer from "react-image-file-resizer";
import { TrashIcon } from "@heroicons/react/outline";

type ImgLoaderProps = {
  serverImg: string;
  pictureUploaded: (img: string) => void;
  pictureDeleted: () => void;
};

// const imgStyle = { width: "171px", height: "171px" };

const resizeFile = (newFile: File) =>
  new Promise<Blob | string | ProgressEvent<FileReader>>((resolve) => {
    Resizer.imageFileResizer(
      newFile,
      300,
      300,
      newFile.type,
      10,
      0,
      (uri) => {
        return resolve(uri);
      },
      "blob"
    );
  });

export const ImgLoader = ({
  serverImg,
  pictureUploaded,
  pictureDeleted,
}: ImgLoaderProps) => {
  const fileImg = createRef<HTMLInputElement>();
  const [status, setStatus] = useState("idle");
  const [imgFile, setImgFile] = useState<File>({} as File);

  const uploadPicture = async (file: File) => {
    try {
      const { s3ImageDto } = await apiService.getCall("resource/images/active");
      setStatus("loading");
      await FetchService.putCallFile(file, s3ImageDto.signedUrlDto.signedUrl);
      const image = await resizeFile(file);
      const newFile = new File([image as BlobPart], "filename", {
        type: file.type,
        lastModified: Date.now(),
      });
      await FetchService.putCallFile(
        newFile,
        s3ImageDto.signedUrlDto.thumbnailSignedUrl
      );
      pictureUploaded(s3ImageDto.signedUrlDto.resourceUri);
      setImgFile(file);
      setStatus("idle");
    } catch (error) {
      setStatus("idle");
    }
  };

  const imgSrc =
    serverImg || imgFile.name ? (
      <img
        className="rounded-lg mb-2 border bg-white border-gray-300 object-cover h-36 w-36"
        src={imgFile.name ? URL.createObjectURL(imgFile) : serverImg}
        alt="Imagen"
      />
    ) : (
      <div className="rounded-lg mb-2 text-gray-700 text-center justify-center bg-gray-400 px-4 border flex items-center border-gray-300 h-36 w-36">
        Imagen sin cargar
      </div>
    );

  return (
    <>
      <div>
        {status !== "loading" ? (
          imgSrc
        ) : (
          <div className="rounded-lg mb-2 border flex items-center bg-white border-gray-300 h-36 w-36">
            <Spinner />
          </div>
        )}
        <div className="flex items-center space-x-2 w-36">
          <button
            className="flex-1 bg-blue-500 btn text-xs font-semibold px-2 py-1"
            type="button"
            disabled={status === "loading"}
            onClick={() => (fileImg.current ? fileImg.current.click() : null)}
          >
            Editar
          </button>
          <button type="button" onClick={() => pictureDeleted()}>
            <TrashIcon className="w-6 text-red-500" />
          </button>
        </div>
        <input
          className="hidden"
          type="file"
          ref={fileImg}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            uploadPicture(event?.target?.files![0])
          }
        />
      </div>
    </>
  );
};
