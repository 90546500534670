import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

type ModalProps = {
  closeModal: () => void;
  size?: string;
  classes?: string;
  isOpen: boolean;
  title: string;
};

export const Modal: React.FC<ModalProps> = ({
  closeModal,
  children,
  size = "3xl",
  classes,
  isOpen,
  title,
}) => {
  return (
    <Transition show={isOpen} appear as={Fragment}>
      <Dialog
        as="div"
        onClose={() => closeModal()}
        className="fixed inset-0 flex items-center justify-center"
      >
        <Dialog.Overlay className="fixed bg-opacity-50 inset-0 bg-gray-600" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={`bg-white relative w-full sm:w-auto p-6 rounded-lg max-h-90 overflow-y-auto max-w-90 sm:max-w-${size} ${classes ? classes : '' }`}
          >
            <div className="flex items-center justify-between space-x-4">
              <Dialog.Title className="text-xl font-semibold uppercase">
                {title}
              </Dialog.Title>
              <button
                id="close-button"
                type="button"
                className="appearance-none font-semibold cursor-pointer hover:opacity-50"
                onClick={() => closeModal()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="w-8 h-8 text-black"
                >
                  <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                </svg>
              </button>
            </div>
            <div>{children}</div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
