import React, { useRef, useEffect, MutableRefObject } from 'react';
import { GoogleMapsLoadingService } from '../services/googleMapsLoadingService';
import { DeliveryZones, LatLng } from '../types';

type EmbeddedDrawingMapProps = {
  className: string;
  center: LatLng;
  defaultPrice: number;
  deliveryZones: DeliveryZones;
  deliveryZonesChangedCallback: (event: DeliveryZones) => void;
};

export const EmbeddedDrawingMap: React.FC<EmbeddedDrawingMapProps> = ({
  className,
  center,
  defaultPrice,
  deliveryZones,
  deliveryZonesChangedCallback,
}: EmbeddedDrawingMapProps) => {
  const mapRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    (async () => {
      const googleMapsDrawingService = await GoogleMapsLoadingService.getInstance().getDrawingService();
      googleMapsDrawingService.updateDefaultPrice(defaultPrice);
    })();
  }, [defaultPrice]);

  useEffect(() => {
    (async () => {
      const googleMapsDrawingService = await GoogleMapsLoadingService.getInstance().getDrawingService();
      googleMapsDrawingService.setDeliveryZoneChangeCallback(deliveryZonesChangedCallback);
    })();
  }, [deliveryZonesChangedCallback]);

  useEffect(() => {
    (async () => {
      const googleMapsDrawingService = await GoogleMapsLoadingService.getInstance().getDrawingService();
      googleMapsDrawingService.initializeMap({
        mapRef,
        center,
      });
    })();
  }, [mapRef, center]);

  useEffect(() => {
    (async () => {
      const googleMapsDrawingService = await GoogleMapsLoadingService.getInstance().getDrawingService();
      googleMapsDrawingService.initializeDeliveryZones(deliveryZones);
    })();
  }, [deliveryZones]);

  return <div className={className} ref={mapRef} />;
};
