import React, { useState, useEffect } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
} from "react-beautiful-dnd";
import { SectionElement } from "./SectionElement";
import apiService from "../services/api";
import { PopUp, PopUphandler } from "./PopUp";
import { Section } from "../types";
import { TAGS } from "../constants";
import { ExclamationIcon } from "@heroicons/react/outline";

type SectionListProps = {
  sectionList: Section[];
  selected: number | null;
  reloadData: () => void;
  selectSection: (sectionId: number) => void;
};

type SectionOrder = Section & { position: number };

export const SectionsList = ({
  sectionList,
  reloadData,
  selectSection,
  selected,
}: SectionListProps) => {
  const [showPopUp, setShowPopUp] = useState("");
  const [sections, setSections] = useState(sectionList);
  const [confirmationPopUp, setConfirmationPopUp] = useState(
    {} as PopUphandler
  );
  const [editSectionForm, setEditSectionForm] = useState({
    locationTags: new Set<string>(),
  } as Section);
  const [status, setStatus] = useState("idle");

  useEffect(() => setSections(sectionList), [sectionList]);

  const removeSection = (event: Event, index: number) => {
    event.preventDefault();
    setConfirmationPopUp({
      handler: async (_event, confirmation: boolean) => {
        if (confirmation) {
          const response = await apiService.deleteCall(
            `menu/section/remove/${sections[index].id}`
          );
          if (!response.error) {
            reloadData();
          }
        }
        setConfirmationPopUp({} as PopUphandler);
      },
      message: "Estas seguro que desea eliminar esta seccion?",
    });
  };

  const editSection = (event: Event, section: Section) => {
    event.preventDefault();
    setShowPopUp("edit");
    setEditSectionForm({
      ...section,
      locationTags: new Set<string>(section.locationTags),
    });
  };

  const changeVisibility = async (id: number, value: boolean) => {
    if (status === "idle") {
      setStatus("updating");
      await apiService.putCall({}, `menu/section/${id}/hide/${value}`);
      reloadData();
      setStatus("idle");
    }
  };

  const handleSectionEdition = async (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    confirmation: boolean
  ) => {
    if (status !== "idle") return;
    if (confirmation) {
      setStatus("updating");
      if (showPopUp === "edit") {
        await apiService.putCall(
          {
            ...editSectionForm,
            locationTags: [...editSectionForm.locationTags],
          },
          "menu/section/update"
        );
      } else {
        await apiService.postCall(
          {
            name: editSectionForm.name,
            locationTags: [...editSectionForm.locationTags],
          },
          "menu/section/add"
        );
      }
      setStatus("idle");
      reloadData();
    }
    setShowPopUp("");
    setEditSectionForm({
      locationTags: new Set<string>(),
    } as Section);
  };

  const addNewSection = async () => {
    setShowPopUp("new");
  };

  const reorder = (list: Section[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const handleChangeLocations = (
    event: { target: { checked: any } },
    value: string
  ) => {
    event.target.checked
      ? editSectionForm.locationTags.add(value)
      : editSectionForm.locationTags.delete(value);

    setEditSectionForm({ ...editSectionForm });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const sectionsOrdered = reorder(
      sections,
      result.source.index,
      result.destination.index
    );

    const sectionsOrders: SectionOrder[] = sectionsOrdered.map(
      (section: Section, index: number) => ({
        ...section,
        position: index,
      })
    );
    apiService.putCall(sectionsOrders, "menu/section/update-order");

    setSections(sectionsOrdered);
  };

  return (
    <div className="flex flex-col h-full overflow-hidden">
      {showPopUp && (
        <PopUp
          closePopUp={handleSectionEdition}
          message={`${showPopUp === "edit" ? "Editar" : "Crear"} Categoría`}
        >
          <div className="">
            <div className="w-auto mt-2">
              <label className="block text-sm font-medium text-gray-700">
                Nombre
              </label>
              <input
                className="input"
                type="text"
                onChange={(event) =>
                  setEditSectionForm({
                    ...editSectionForm,
                    name: event.target.value,
                  })
                }
                value={editSectionForm.name}
              />
              <label className="mt-4 block uppercase tracking-wide text-gray-700 text-xs font-bold">
                Grupo de punto de ventas
              </label>
              <p className="text-gray-600 text-xs mb-2">
                Seleccione el grupo/s de punto de venta en el que desea que la
                categoría se muestre.
              </p>
              {TAGS.map((tagName) => (
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="input text-primary-300"
                    checked={editSectionForm.locationTags.has(tagName.value)}
                    onChange={(event) =>
                      handleChangeLocations(event, tagName.value)
                    }
                  />
                  <div className="ml-2">{tagName.label}</div>
                </label>
              ))}
            </div>
          </div>
        </PopUp>
      )}
      {confirmationPopUp.message && (
        <PopUp
          closePopUp={confirmationPopUp.handler}
          message={confirmationPopUp.message}
        />
      )}

      <div className="flex-shrink-0 flex items-start justify-between mb-2 space-x-2">
        <div className="text-xl">
          <div>Categorías del Menu</div>
          <p className="text-gray-600 text-sm">
            Arrastre las secciones para cambiar su orden
          </p>
        </div>
        <button
          className="btn text-xs bg-blue-500 hover:opacity-70 px-2"
          onClick={addNewSection}
        >
          Agregar categoría
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: DroppableProvided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sections.length === 0 && (
                  <div className="mt-2 text-gray-600 text-center border-t border-gray-400 pt-4">
                    <ExclamationIcon className="h-8 mx-auto" />
                    <span className="font-semibold">
                      Aún no existen categorías creadas! <br />
                    </span>
                    <span className="text-sm">
                      Para poder ingresar productos al menú es necesario crear
                      categorías.
                    </span>
                  </div>
                )}
                {sections.map((section: Section, index: number) => (
                  <Draggable
                    key={section.id}
                    draggableId={`id-${section.id}`}
                    index={index}
                  >
                    {(providedInner) => (
                      <div
                        ref={providedInner.innerRef}
                        {...providedInner.draggableProps}
                        {...providedInner.dragHandleProps}
                      >
                        <SectionElement
                          section={section}
                          selected={selected === section.id}
                          key={section.id}
                          index={index}
                          handleClick={() => selectSection(section.id)}
                          removeSection={removeSection}
                          editSection={editSection}
                          changeVisibility={changeVisibility}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
