import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { SubCategoryElement } from "../components/SubCategoryElement";
import apiService from "../services/api";
import { PopUp, PopUphandler } from "./PopUp";
import { Modal } from "./Modal";
import { alertService } from "../services/alertService";
import { Button } from "./Button";
import { Section, SubCategory, Product } from "../types";

type ProductProps = {
  product: Product;
  reloadItem: () => void;
  changeProduct: (product: Product) => void;
  clickCategory: (section: SubCategory) => void;
  selected: number;
};

type SubCategoryOrder = SubCategory & { position: number };

export const SubCategoryList = ({
  product,
  reloadItem,
  changeProduct,
  clickCategory,
  selected,
}: ProductProps) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmationPopUp, setConfirmationPopUp] = useState(
    {} as PopUphandler
  );
  const [newCategory, setNewSubCategory] = useState("");
  const [menu, setMenu] = useState({} as any);
  const [importSelected, setImportSelected] = useState(null as any);
  const [status, setStatus] = useState("idle");

  const reorder = (
    list: SubCategory[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEndSubCategory = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const subItemsOrdered = reorder(
      product.subItemsCategories,
      result.source.index,
      result.destination.index
    );

    const subCategoriesUpdated: SubCategoryOrder[] = subItemsOrdered.map(
      (subCategory: SubCategory, index: number) => ({
        ...subCategory,
        position: index,
      })
    );

    apiService.patchCall(
      subCategoriesUpdated,
      `/menu/subitem/category/update-order/${product.id}`
    );

    changeProduct({
      ...product,
      subItemsCategories: subItemsOrdered,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiService.getCall("menu");
      setMenu(response);
    };
    fetchData();
  }, []);

  const addNewSubCategory = async () => {
    if (status === "idle") {
      setStatus("updating");
      await apiService.postCall(
        { minSelection: 0, name: newCategory },
        `menu/subitem/category/add/${product.id}`
      );
      setNewSubCategory("");
      reloadItem();
      setStatus("idle");
    }
  };

  const removeSubCategory = (subCategoryId: number) => {
    setConfirmationPopUp({
      handler: async (_event, confirmation: boolean) => {
        if (confirmation) {
          const response = await apiService.deleteCall(
            `menu/subitem/category/remove/${subCategoryId}`
          );
          if (!response.error) {
            reloadItem();
          }
        }
        setConfirmationPopUp({} as PopUphandler);
      },
      message: "Estas seguro que desea eliminar esta sub categoría?",
    });
  };

  const changeVisibilitySubCategory = async (id: number, value: boolean) => {
    if (status === "idle") {
      setStatus("updating");
      await apiService.patchCall(
        {},
        `menu/subitem/category/${id}/hide/${value}`
      );
      reloadItem();
      setStatus("idle");
    }
  };

  const importItems = async () => {
    const response = await apiService.postCall(
      {},
      `menu/copy/${importSelected.label}/${importSelected.value}/to/item/${product.id}`
    );
    if (!response.error) {
      reloadItem();
      setShowModal(false);
      alertService.success("Valores importadas correctamente", {
        autoClose: true,
        keepAfterRouteChange: false,
      });
    }
  };

  return (
    <>
      {confirmationPopUp.message && (
        <PopUp
          closePopUp={confirmationPopUp.handler}
          message={confirmationPopUp.message}
        />
      )}
      {showModal && (
        <Modal
          closeModal={() => setShowModal(false)}
          isOpen={showModal}
          title="Importar Subitems"
        >
          <p className="my-1  text-gray-600">
            Para poder reutilizar configuraciones ya realizadas para otros
            productos o categorias, existe la posibilidad de importar.
          </p>
          <div className="text-xl my-2">Desde otro Producto</div>
          {menu.sections
            .find((section: Section) => section.id === product.sectionId)
            .items.map((item: Product) =>
              item.id !== product.id ? (
                <div key={item.id} className="flex items-center">
                  <input
                    name="import"
                    type="radio"
                    value={item.id}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setImportSelected({
                        value: event.target.value,
                        label: "item",
                      })
                    }
                  />
                  <label className="ml-2" htmlFor="">
                    {item.name}
                  </label>
                </div>
              ) : null
            )}
          <div className="text-xl my-2">Desde otra Categoría</div>
          {menu.sections.map((section: Section) => (
            <div key={section.name} className="flex items-center">
              <input
                name="import"
                type="radio"
                value={section.id}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setImportSelected({
                    value: event.target.value,
                    label: "section",
                  })
                }
              />
              <label className="ml-2" htmlFor="">
                {section.name}
              </label>
            </div>
          ))}
          <div className="mt-3">
            <Button text="IMPORTAR" onClick={importItems} />
          </div>
        </Modal>
      )}
      <div className="bg-white rounded-lg p-4 flex-col flex">
        <div className="flex items-center space-x-2 justify-between">
          <div className="text-xl mb-2">{product.name}</div>
          <Button
            type="secondary"
            text="IMPORTAR SUBITEMS"
            onClick={() => setShowModal(true)}
          />
        </div>
        <div className="overflow-y-auto h-auto flex-grow">
          <div className="mt-2 flex items-end w-full">
            <div className="pr-3 mb-6 md:mb-0 flex-grow">
              <input
                className="input w-full"
                type="text"
                placeholder="Nueva Sub Categoría"
                value={newCategory}
                onChange={(event) => {
                  setNewSubCategory(event.target.value);
                }}
              />
            </div>
            <Button
              type="secondary"
              onClick={addNewSubCategory}
              disabledProp={!newCategory}
              text="AGREGAR"
            />
          </div>
          {product.subItemsCategories.length === 0 ? (
            <p className="mt-2 text-gray-600">
              El producto aun no contiene sub categorias de personalizacion.
            </p>
          ) : (
            <div>
              <div className="text-xl my-2">Sub Categorias Activas</div>
              <p className="text-xs text-gray-600 my-2">
                Seleccionar sub categoria para poder editar
              </p>
            </div>
          )}
          <DragDropContext onDragEnd={onDragEndSubCategory}>
            <Droppable droppableId="subcategories">
              {(provided: DroppableProvided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {product.subItemsCategories.map(
                    (subCategory: SubCategory, index: number) => (
                      <Draggable
                        key={subCategory.id}
                        draggableId={`id-${subCategory.id}`}
                        index={index}
                      >
                        {(providedInner) => (
                          <div
                            ref={providedInner.innerRef}
                            {...providedInner.draggableProps}
                            {...providedInner.dragHandleProps}
                          >
                            <SubCategoryElement
                              handleClick={clickCategory}
                              changeVisibility={changeVisibilitySubCategory}
                              subCategory={subCategory}
                              selected={subCategory.id === selected}
                              key={subCategory.id}
                              removeSubCategory={removeSubCategory}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </>
  );
};
