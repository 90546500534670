import React, { useState } from 'react';
import { Spinner } from './Spinner';

type ButtonProps = {
  type?: 'primary' | 'secondary';
  text: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabledProp?: boolean;
  isButtonForm?: boolean;
  showSpinner?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  children,
  type = 'primary',
  disabledProp = false,
  isButtonForm = false,
  showSpinner = false,
}) => {
  const cssClasses = type === 'primary' ? 'bg-primary-300' : 'bg-blue-500';
  const [status, setStatus] = useState('idle');

  const handleClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setStatus('loading');
    if (!isButtonForm && onClick) {
      await onClick(event);
    }
    setStatus('idle');
  };

  return (
    <button
      className={`${cssClasses} btn`}
      onClick={(event) => handleClick(event)}
      type={isButtonForm ? 'submit' : 'button'}
      disabled={status === 'loading' || disabledProp}
    >
      {text ? <div>&nbsp;{text}&nbsp;</div> : null}
      {children && children}
      {status === 'loading' ? <Spinner size="4" /> : null}
      {showSpinner ? <Spinner size="4" /> : null}
    </button>
  );
};
