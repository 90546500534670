import React, { useState, useEffect } from "react";
import apiService from "../services/api";
import { ImgLoader } from "../components/ImgLoader";
import { Spinner } from "../components/Spinner";
import { alertService } from "../services/alertService";
import { PageTitle } from "../components/PageTitle";
import { Button } from "../components/Button";
// import apiService from "../services/api";

type CustomizationData = {
  label: string;
  value: string;
  color: string;
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  image5: string;
  image6: string;
  mainLogo: string;
  themeName: string;
  categoryDisplayStrategy: string;
};

const Customization = () => {
  const [config, setConfig] = useState({} as CustomizationData);
  const [status, setStatus] = useState("iddle");
  const availableAccordionOptions = [
    {
      label: "Todas Cerradas",
      value: "none",
    },
    {
      label: "Todas Abiertas",
      value: "all",
    },
    {
      label: "Solo la primera",
      value: "first",
    },
  ];

  const availablecolors = [
    {
      label: "Azul Medio",
      value: "mid-blue",
      color: "#312B81",
    },
    {
      label: "Rojo",
      value: "red",
      color: "#d8284F",
    },
    {
      label: "Rosa",
      value: "pink",
      color: "#f087a5",
    },
    {
      label: "Azul Oscuro",
      value: "dark-blue",
      color: "#2e6472",
    },
    {
      label: "Negro",
      value: "dark",
      color: "#38322e",
    },
    {
      label: "Turquesa",
      value: "green-blue",
      color: "#33c3c0",
    },
    {
      label: "Violeta",
      value: "purple",
      color: "#ab3cc3",
    },
    {
      label: "Amarillo",
      value: "yellow",
      color: "#968126",
    },
    {
      label: "Azul",
      value: "blue",
      color: "#3880ff",
    },
    {
      label: "Naranja",
      value: "orange",
      color: "#ff8000",
    },
  ];

  const fetchData = async () => {
    setStatus("loading");
    try {
      const response = await apiService.getCall("menu");
      setConfig(response.attributes);
      setStatus("resolved");
    } catch (error) {
      setStatus("error");
    }
  };

  const saveAccordion = async () => {
    const response = await apiService.putCall(
      { key: "categoryDisplayStrategy", value: config.categoryDisplayStrategy },
      "menu/attribute/update"
    );

    if (!response.error) {
      fetchData();
    }
  };

  const saveColors = async () => {
    const response = await apiService.putCall(
      { key: "themeName", value: config.themeName },
      "menu/attribute/update"
    );

    if (!response.error) {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (status === "iddle" || status === "loading") {
    return (
      <div className="p-4">
        <Spinner />
      </div>
    );
  }

  const pictureUploaded = async (url: string, key: string) => {
    try {
      await apiService.putCall({ key, value: url }, "menu/attribute/update");
      alertService.info("Se ha actualizado la imagen correctamente", {
        autoClose: true,
        keepAfterRouteChange: false,
      });
    } catch (error) {
      alertService.error(
        "Se ha ocurrido un error cargando la imagen, intente nuevamente",
        {
          autoClose: true,
          keepAfterRouteChange: false,
        }
      );
    }
  };

  const deletePicture = async (key: string) => {
    pictureUploaded("", key);
    setConfig({ ...config, [key]: "" });
  };

  if (status === "error") {
    return (
      <div>
        <h1 className="mb-8">Personalizacion</h1>
        <div className="section-border mb-4 p-4">
          Hubo un error al cargar la página, por favor intente nuevamente.
        </div>
      </div>
    );
  }
  return (
    <div>
      <PageTitle title="Diseña como tus clientes ven tu tienda" />
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
        <div className="section-border mb-4 p-4 flex flex-col">
          <div className="text-xl">Color principal de la aplicación</div>
          <p className="text-gray-600 text-sm">
            Seleccione el color predominante en la aplicación
          </p>
          <div
            className="flex-grow my-4 gap-2 grid grid-cols-fill"
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(40px, 1fr))",
            }}
          >
            {availablecolors.map((option) => (
              <div
                key={option.value}
                className={`w-8 h-8 mx-auto rounded-sm cursor-pointer hover:opacity-70 shadow-md ${
                  option.value === config.themeName && "ring-4 ring-primary-300"
                }`}
                style={{ background: option.color }}
                onClick={() =>
                  setConfig({ ...config, themeName: option.value })
                }
              />
            ))}
          </div>
          <div>
            <Button text="Guardar Cambios" onClick={saveColors} />
          </div>
        </div>
        <div className="section-border mb-4 p-4 flex flex-col justify-between">
          <div>
            <div className="mb-2">
              <div className="text-xl">Opciones para el menú despleglable</div>
              <p className="text-gray-600 text-sm mb-2">
                Determina como ven los usuarios las categorías del menú al
                acceder a la aplicación ServiteOnline.
              </p>
            </div>
            <label className="block text-sm font-medium text-gray-700">
              Seleccionar Opción
            </label>
            <select
              className="input"
              value={config.categoryDisplayStrategy}
              onChange={(event) => {
                setConfig({
                  ...config,
                  categoryDisplayStrategy: event.target.value,
                });
              }}
            >
              {availableAccordionOptions.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex mt-3 items-center">
            <Button text="Guardar Cambios" onClick={saveAccordion} />
          </div>
        </div>
      </div>
      <div className="section-border p-4">
        <div>
          <div className="text-xl mb-2">Logo para la barra de navegación</div>
          <p className="text-gray-600 text-sm mt-2 mb-3">Preferentemente seleccionar una imágen pequeña. Resolución recomendada 60x40 píxeles.</p>
          <ImgLoader
            serverImg={config.mainLogo}
            pictureUploaded={(url: string) => pictureUploaded(url, "mainLogo")}
            pictureDeleted={() => deletePicture("mainLogo")}
          />
          <div>
            <div className="text-xl mt-4 mb-2">Galeria de Imágenes para pantalla de inicio</div>
            <p className="text-gray-600 text-sm mt-2 mb-3">Preferentemente seleccionar imágenes anchas. Resolución recomendada 400x230 píxeles.</p>
            <div
              className="grid gap-4"
              style={{
                gridTemplateColumns: "repeat(auto-fill, minmax(144px, 1fr))",
              }}
            >
              <ImgLoader
                serverImg={config.image1}
                pictureUploaded={(url: string) =>
                  pictureUploaded(url, "image1")
                }
                pictureDeleted={() => deletePicture("image1")}
              />
              <ImgLoader
                serverImg={config.image2}
                pictureUploaded={(url: string) =>
                  pictureUploaded(url, "image2")
                }
                pictureDeleted={() => deletePicture("image2")}
              />
              <ImgLoader
                serverImg={config.image3}
                pictureUploaded={(url: string) =>
                  pictureUploaded(url, "image3")
                }
                pictureDeleted={() => deletePicture("image3")}
              />
              <ImgLoader
                serverImg={config.image4}
                pictureUploaded={(url: string) =>
                  pictureUploaded(url, "image4")
                }
                pictureDeleted={() => deletePicture("image4")}
              />
              <ImgLoader
                serverImg={config.image5}
                pictureUploaded={(url: string) =>
                  pictureUploaded(url, "image5")
                }
                pictureDeleted={() => deletePicture("image5")}
              />
              <ImgLoader
                serverImg={config.image6}
                pictureUploaded={(url: string) =>
                  pictureUploaded(url, "image6")
                }
                pictureDeleted={() => deletePicture("image6")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customization;
