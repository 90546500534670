import React, { createRef, useEffect, useState } from "react";
import apiService, { FetchService } from "../services/api";
import { Spinner } from "../components/Spinner";
import { alertService } from "../services/alertService";
import { PageTitle } from "../components/PageTitle";
import { Button } from "../components/Button";
import { TAGS } from "../constants";
import { PopUp, PopUphandler } from "../components/PopUp";
import { RenderingStatus } from "../types";
import Switch from "react-switch";
import { TrashIcon, ClipboardCopyIcon } from "@heroicons/react/solid";

type Location = {
  id: string;
  name: string;
  types: string[];
  url: string;
  tag: string;
  disabled: boolean;
  onlinePaymentAvailable: boolean;
  cashPaymentAvailable: boolean;
  cashPaymentEnabled: boolean;
  onlinePaymentEnabled: boolean;
  new: boolean;
};

interface LocationModal extends Location {
  show: boolean;
}

type Frame = {
  id: number;
  name: string;
  displayImageLink: string;
};

const Locations = () => {
  const fileInput = createRef<HTMLInputElement>();
  const [status, setStatus] = useState<RenderingStatus>("idle");
  const [newLocation, setNewLocation] = useState("");
  const [locations, setLocations] = useState([] as Location[]);
  const [frames, setFrames] = useState([] as Frame[]);
  const [frameSelected, setFrameSelected] = useState({} as Frame);
  const [locationSelected, setLocationSelected] = useState({} as LocationModal);
  const [confirmationPopUp, setConfirmationPopUp] = useState({} as PopUphandler);

  const fetchData = async (showSpinner = false) => {
    !showSpinner && setStatus("loading");
    const locationsPromise = await apiService.getCall("location");
    const framesPromise = await apiService.getCall("qr/frames");
    Promise.all([locationsPromise, framesPromise])
      .then(() => {
        setLocations(locationsPromise);
        setFrames(framesPromise);
        if (framesPromise.length > 0) {
          setFrameSelected(framesPromise[0]);
        }
        return;
      })
      .catch(() => {
        alertService.error(
          "Hubo un problema al cargar los datos, vuelva a intentarlo nuevamente."
        );
      })
      .finally(() => {
        !showSpinner && setStatus("loaded");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteLocation = (location: Location) => {
    setConfirmationPopUp({
      handler: async (_event, confirmation: boolean) => {
        if (confirmation) {
          apiService
            .deleteCall(`location/remove/${location.id}`)
            .then(() => {
              fetchData();
              return alertService.success("Punto de venta borrado con <C3><A9>xito");
            })
            .catch(() =>
              alertService.error(
                "Hubo un problema al borrar el punto de venta, por favor vuelva a intentarlo"
              )
            );
        }
        setConfirmationPopUp({} as PopUphandler);
      },
      message: `Estas seguro que desea borrar el punto de venta: "${location.name}" ?`,
    });
  };

  useEffect(() => {
    if (!locationSelected.id || locationSelected.new) return;
    const updateLocation = async () => {
      apiService
        .putCall(locationSelected, "location/update")
        .then((response) => {
          if (response.error) {
            alertService.error(
              "Error al guardar los cambios, por favor intente nuevamente.",
              {
                autoClose: true,
                keepAfterRouteChange: false,
              }
            );
          } else {
            fetchData(true);
          }
        });
    };
    updateLocation();
  }, [locationSelected]);

  const toggleLocationService = async (newValue: boolean) => {
    setLocationSelected({
      ...locationSelected,
      disabled: !newValue,
      new: false,
    });
  };

  const changeGroup = async (newValue: string) => {
    setLocationSelected({ ...locationSelected, tag: newValue, new: false });
  };

  const changeType = (value: string) => {
    const indexValue = locationSelected.types.findIndex(
      (item) => item === value
    );
    const { types } = locationSelected;
    if (indexValue !== -1) {
      types.splice(indexValue, 1);
    } else {
      types.push(value);
    }
    setLocationSelected({ ...locationSelected, types, new: false });
  };

  const updatePayments = (label: string, value: boolean) => {
    setLocationSelected({ ...locationSelected, [label]: value, new: false });
  };

  const addNewLocation = async () => {
    const response = await apiService.postCall(
      [{ name: newLocation }],
      "location/add"
    );
    if (response.error) {
      alertService.error("Se ha excedido el limite de QRs disponibles", {
        autoClose: true,
        keepAfterRouteChange: false,
      });
    } else {
      setNewLocation("");
      fetchData();
    }
  };

  const uploadQRCode = (url: string) => {
    apiService
      .putCall(
        {
          frameImageLink: url,
        },
        "qr/frame/upload"
      )
      .then(() => {
        fetchData();
        return alertService.success("Archivo subido con éxito!");
      })
      .catch(() =>
        alertService.error(
          "Ha ocurrido un error, revise el archivo e intente nuevamente"
        )
      );
  };

  const uploadPicture = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const fileToSend: File = event.target.files[0];
    try {
      const { s3ImageDto } = await apiService.getCall("resource/images/active");
      setStatus("loading");
      await FetchService.putCallFile(
        fileToSend,
        s3ImageDto.signedUrlDto.signedUrl
      );
      const newFile = new File([fileToSend], "filename", {
        type: fileToSend.type,
        lastModified: Date.now(),
      });
      await FetchService.putCallFile(
        newFile,
        s3ImageDto.signedUrlDto.thumbnailSignedUrl
      );
      uploadQRCode(s3ImageDto.signedUrlDto.resourceUri);
      setStatus("idle");
    } catch (error) {
      setStatus("idle");
    }
  };

  const downloadQRCode = async (
    locationsDownload: string[],
    frameId: number
  ) => {
    setStatus("generatingQR");
    const response = await apiService.putCall(
      locationsDownload,
      `qr/generate/${frameId}`,
      "blob"
    );
    if (!response.error) {
      const blob = new Blob([response]);
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `QRs.pdf`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } else {
      alertService.error(
        "Hubo un problema en la generación del código, porfavor vuelva a intentarlo nuevamente"
      );
    }
    setStatus("loaded");
  };

  if (status === "idle" || status === "loading") {
    return (
      <div className="p-4">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="h-full min-h-0 relative flex flex-col">
      {confirmationPopUp.message && (
        <PopUp
          closePopUp={confirmationPopUp.handler}
          message={confirmationPopUp.message}
        />
      )}
      <PageTitle title="Puntos de venta" />
      <div className="flex-grow lg:overflow-y-hidden lg:flex lg:gap-4">
        <div className="p-4 flex flex-col lg:overflow-y-hidden w-full mb-4 lg:mb-0 lg:w-1/2 xl:w-1/3 section-border">
          <p className="text-gray-600 mb-3 text-sm">
            Crea diferentes QRs y enlaces para falicitar el ingreso a tus
            productos.
          </p>
          <form
            className="flex items-center w-full space-x-4"
            onSubmit={(e) => {
              e.preventDefault();
              addNewLocation();
            }}
          >
            <input
              value={newLocation}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setNewLocation(event.target.value)
              }
              placeholder="Nombre"
              className="input w-full"
              type="text"
            />
            <Button
              isButtonForm
              text="AGREGAR"
              disabledProp={!newLocation}
              onClick={() => addNewLocation()}
              type="secondary"
            />
          </form>
          <h2 className="text-xl font-semibold text-gray-900 mt-3">
            Enlaces creados
          </h2>
          <div className="overflow-y-auto mt-2 mb-4">
            <ul className="divide-y divide-gray-200 border-gray">
              {locations.map((location: Location) => (
                <li
                  className={`cursor-pointer flex justify-between items-center p-2 ${location.id === locationSelected.id
                      ? "text-blue-500 font-semibold "
                      : "hover:bg-gray-50"
                    }`}
                  key={location.name}
                  onClick={() =>
                    setLocationSelected({ ...location, show: true, new: true })
                  }
                >
                  <div className="flex items-center">{location.name}</div>
                  <TrashIcon
                    className={`h-6 w-6 cursor-pointer hover:opacity-75 ${location.id === locationSelected.id
                        ? "text-blue-500"
                        : "text-red-600"
                      } fill-current `}
                    aria-hidden="true"
                    onClick={(_) => deleteLocation(location)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        {locationSelected.show && (
          <div className="p-4 flex-1 pl-4 bg-white rounded-lg border border-gray-300 overflow-y-auto">
            <div className="text-2xl mb-3 font-semibold">
              {locationSelected.name}
            </div>
            <div>
              <div className="p-4 flex space-x-4 items-center bg-gray-100 rounded-lg max-w-fit">
                <h3 className="text-gray-600">Acceso: </h3>
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="text-primary-300 break-all font-semibold hover:opacity-50 hover:underline"
                  href={locationSelected.url}
                >
                  {locationSelected.url}
                </a>
                <button title="Copiar enlace">
                  <ClipboardCopyIcon
                    className={`h-6 w-6 cursor-pointer hover:opacity-75 fill-current text-primary-300 `}
                    aria-hidden="true"
                    onClick={() => {
                      navigator.clipboard.writeText(locationSelected.url);
                    }}
                  />
                </button>
              </div>
            </div>
            {/* Beginning Second panel */}
            <div className="xl:flex xl:space-x-4 lg:my-8 xl:divide-x-2">
              <div className="pr-4">
                <div className="text-lg my-2 text-gray-700">
                  Configuración de Acceso
                </div>
                <div className="my-2 flex items-center">
                  <label className="block text-sm font-medium text-gray-700 pr-4">
                    Habilitar o deshabilitar temporalmente.
                  </label>
                  <Switch
                    offColor="#ff0000"
                    onColor="#409a6d"
                    onChange={toggleLocationService}
                    checked={!locationSelected.disabled}
                  />
                </div>
                {locationSelected.cashPaymentAvailable ? (
                  <div className="my-2 flex items-center">
                    <input
                      className="input text-primary-300"
                      type="checkbox"
                      onChange={(event) =>
                        updatePayments(
                          "cashPaymentEnabled",
                          event.target.checked
                        )
                      }
                      checked={locationSelected.cashPaymentEnabled}
                    />
                    <div className="block text-sm font-medium text-gray-700 pl-2">
                      Permitir pagos en efectivo
                    </div>
                  </div>
                ) : null}
                {locationSelected.onlinePaymentAvailable ? (
                  <div className="my-2 flex items-center">
                    <input
                      className="input text-primary-300"
                      type="checkbox"
                      onChange={(event) =>
                        updatePayments(
                          "onlinePaymentEnabled",
                          event.target.checked
                        )
                      }
                      checked={locationSelected.onlinePaymentEnabled}
                    />
                    <div className="block text-sm font-medium text-gray-700 pl-2">
                      Permitir pagos con MercadoPago
                    </div>
                  </div>
                ) : null}
                <div className="my-2 flex items-center">
                  <input
                    className="input text-primary-300"
                    type="checkbox"
                    onChange={() => changeType("delivery")}
                    checked={locationSelected.types.includes("delivery")}
                  />
                  <div className="block text-sm font-medium text-gray-700 pl-2">
                    Servicio de Delivery
                  </div>
                </div>
                <div className="my-2 flex items-center">
                  <input
                    className="input text-primary-300"
                    type="checkbox"
                    onChange={() => changeType("takeAway")}
                    checked={locationSelected.types.includes("takeAway")}
                  />
                  <div className="block text-sm font-medium text-gray-700 pl-2">
                    Servicio de Take Away
                  </div>
                </div>
                <div className="mb-2">
                  <div className="block text-sm font-medium text-gray-700 pr-2 flex-grow-0 mb-1">
                    Grupo
                  </div>
                  <div>
                    <select
                      value={locationSelected.tag}
                      onChange={(event) => {
                        changeGroup(event.target.value);
                      }}
                      className="input"
                    >
                      {TAGS.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {frames.length > 0 && (
                <div className="xl:pl-8">
                  <div className="text-lg my-2 text-gray-700">
                    Impresión de Códigos QRs
                  </div>
                  <label className="block text-sm font-medium text-gray-700">
                    Modelo de plantilla
                  </label>
                  <select
                    value={frameSelected.id}
                    onChange={(event) => {
                      const index = frames.findIndex(
                        (frame: Frame) =>
                          frame.id === Number(event.target.value)
                      );
                      setFrameSelected(frames[index]);
                    }}
                    className="input"
                  >
                    {frames.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>

                  <img
                    className="rounded border-gray-400 border mt-3 w-64"
                    src={frameSelected.displayImageLink}
                    alt=""
                  />
                  <button
                    className="block lg:w-full mt-4 btn bg-blue-500 text-xs"
                    disabled={status === "generatingQR"}
                    onClick={() =>
                      downloadQRCode([locationSelected.id], frameSelected.id)
                    }
                  >
                    Descargar Codigo QR
                  </button>
                  <input
                    ref={fileInput}
                    className="hidden"
                    type="file"
                    accept="image/*"
                    name="file"
                    onChange={(event) => uploadPicture(event)}
                  />
                  <button
                    className="block lg:w-full mt-4 btn bg-blue-500 text-xs"
                    disabled={status === "generatingQR"}
                    onClick={() =>
                      fileInput.current ? fileInput.current.click() : null
                    }
                  >
                    Cargar nueva plantilla
                  </button>
                </div>
              )}
            </div>
            {/* End Second panel */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Locations;
