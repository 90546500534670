import { Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";

export type PopUphandler = {
  handler: (event: React.MouseEvent<HTMLElement>, value: boolean) => void;
  message: string;
};

type PopUpProps = {
  closePopUp: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: boolean
  ) => void;
  message: string;
};

export const PopUp: React.FC<PopUpProps> = ({
  closePopUp,
  message,
  children,
}) => {
  document.documentElement.classList.add("overflow-y-hidden");
  const close = (event: React.MouseEvent<HTMLElement>, value: boolean) => {
    if (event.currentTarget !== event.target) return;
    closePopUp(event, value);
    document.documentElement.classList.remove("overflow-y-hidden");
  };
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const acceptHandleClick = async (event: any) => {
    setButtonDisabled(true);
    await closePopUp(event, true);
    setButtonDisabled(false);
  };
  return (
    <Transition
      as={Fragment}
      show={true}
      appear
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div
        aria-hidden="true"
        onClick={(event) => close(event, false)}
        className="absolute z-10 inset-0 flex justify-center items-center bg-gray-700 bg-opacity-75"
      >
        <form
          className="bg-white p-4 w-full max-w-xs sm:max-w-lg rounded-lg"
          onSubmit={(e) => {
            e.preventDefault();
            acceptHandleClick(e);
          }}
        >
          {message && <div className="mb-4 text-xl">{message}</div>}
          {children && <div className="mb-4">{children}</div>}
          <div className="space-x-2">
            <button
              type="submit"
              className="btn bg-primary-300 disabled:opacity-50"
              disabled={buttonDisabled}
            >
              ACEPTAR
            </button>
            <button
              type="button"
              disabled={buttonDisabled}
              className="btn bg-red-600 disabled:opacity-50"
              onClick={(event) => close(event, false)}
            >
              CANCELAR
            </button>
          </div>
        </form>
      </div>
    </Transition>
  );
};
