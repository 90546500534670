import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const alertSubject = new Subject<AlertMessage>();
const defaultId = 'default-alert';

export type AlertType = {
  id: string;
  message: string;
  type: string;
};

const audio = new Audio('/bell.mp3');

export type AlertMessage = {
  message: string;
  autoClose: boolean;
  keepAfterRouteChange: boolean;
  id?: string;
  type: string;
};

export const AlertTypes = {
  Success: 'Success',
  Error: 'Error',
  Info: 'Info',
};

type AlertOptions = {
  autoClose: boolean;
  keepAfterRouteChange: boolean;
};

// enable subscribing to alerts observable
function onAlert(id = defaultId) {
  return alertSubject
    .asObservable()
    .pipe(filter((x: AlertMessage) => x && x.id === id));
}

// core alert method
function alert(newAlert: AlertMessage) {
  newAlert.id = newAlert.id || defaultId;
  alertSubject.next(newAlert);
}

// convenience methods
function success(
  message: string,
  options: AlertOptions = {
    autoClose: true,
    keepAfterRouteChange: true,
  }
) {
  alert({ ...options, type: AlertTypes.Success, message });
}

function error(
  message: string,
  options: AlertOptions = {
    autoClose: true,
    keepAfterRouteChange: true,
  }
) {
  alert({ ...options, type: AlertTypes.Error, message });
}

function info(
  message: string,
  options: AlertOptions = {
    autoClose: true,
    keepAfterRouteChange: true,
  }
) {
  alert({ ...options, type: AlertTypes.Info, message });
}

function clearSound() {
  audio.pause();
  audio.currentTime = 0;
}

function playSound(maxTime: number) {
  audio.load();
  const miliSeconds = Math.max(maxTime * 1000, 2000);
  audio.loop = true;
  audio.play();
  setTimeout(() => {
    audio.pause();
    audio.loop = false;
    audio.currentTime = 0;
  }, miliSeconds);
}

function notifyMe(message: string) {
  let notification: Notification;
  // Let's check if the browser supports notifications
  if ('Notification' in window) {
    // Let's check whether notification permissions have already been granted
    if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      notification = new Notification(message);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission()
        .then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === 'granted') {
            notification = new Notification(message);
          }

          return notification;
        })
        .catch(() => {});
    }
  }
}

export const alertService = {
  onAlert,
  success,
  error,
  info,
  notifyMe,
  playSound,
  clearSound,
};
