import React, { useState, useEffect } from "react";
import { Order, OrderItem, SubItem, UserInfo } from "../types";

type OrderDetailProps = {
  order: Order;
  hideButtons?: boolean;
  reference: React.MutableRefObject<any>;
  userInfo?: UserInfo;
};

export const OrderDetail: React.FC<OrderDetailProps> = ({
  order,
  children,
  reference,
  userInfo,
}) => {
  const [paymentInfoHidden, setPaymentInfoHidden] = useState(true);
  const statusClass: { [key: string]: { bg: string; text: string } } = {
    SUCCESS: { bg: "green-200", text: "green-500" },
    BAD: { bg: "red-200", text: "red-500" },
    IN_PROGRESS: { bg: "orange-200", text: "orange-500" },
    PENDING: { bg: "orange-200", text: "orange-500" },
  };

  useEffect(() => {
    setPaymentInfoHidden(true);
  }, [order]);

  return (
    <div translate="no">
      <div ref={reference} className="print:w-72 print:mx-auto">
        <div className="flex justify-between items-center mb-2 print:text-center">
          <div className="text-xl tracking-wide font-semibold uppercase">
            Orden #{order.storeOrderId}
          </div>
          <div className="flex space-x-4 items-center">
            <div className="uppercase rounded-sm font-semibold text-xs px-2 py-1 print:hidden bg-gray-300 text-black ">
              {order.locationName}
            </div>
            <div
              className={`rounded-sm font-bold text-xs px-2 py-1 print:hidden bg-${
                statusClass[order.statusType].bg
              } text-${statusClass[order.statusType].text} opacity-75`}
            >
              {order.status.toLocaleUpperCase()}
            </div>
          </div>
        </div>
        <div className="hidden print:block print:text-black">
          {order.locationName}
        </div>
        <div className="text-gray-600 flex-shrink-0 print:text-black">
          {new Date(order.sentDate).toLocaleDateString()} -&nbsp;
          {new Date(order.sentDate).toLocaleTimeString()}
        </div>
        {order.identificationName && (
          <div className="bg-gray-200 p-2 rounded-md mt-2 mb-4 flex-col print:bg-white print:block print:p-0 break-words">
            <span className="text-gray-900 font-semibold print:text-black">
              {order.identificationNameLabel}
            </span>
            <p className="text-gray-600 print:text-black break-all max-w-fit">
              {order.identificationName}
            </p>
          </div>
        )}
        {order.additionalInfo && (
          <div className="bg-gray-200 p-2 rounded-md mt-2 mb-4 flex-col print:bg-white print:block print:p-0 break-words">
            <span className="text-gray-900 font-semibold print:text-black">
              Observaciones
            </span>
            <p className="text-gray-600 print:text-black break-all max-w-fit">
              {order.additionalInfo}
            </p>
          </div>
        )}
        <div className="p-3 bg-gray-200 rounded-lg my-2 print:bg-transparent print:p-0">
          <div className="flex items-start flex-wrap justify-between">
            <div className="flex-shrink-0 print:text-black print:uppercase print:font-bold">
              <div className="text-sm font-medium text-gray-900 print:text-black print:uppercase print:font-bold print:w-full">
                Contacto
                {userInfo && userInfo?.totalCount > 0 && (
                  <span className="text-xs text-gray-600 pl-2 uppercase font-bold print:hidden w-max">
                    {userInfo.totalCount} pedidos realizados
                  </span>
                )}
              </div>
            </div>
            <span className="pl-2 print:pl-0 tracking-wide text-gray-600">
              <a
                className="hover:opacity-75 underline text-sm print:text-black print:no-underline"
                rel="noopener noreferrer"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=${order.phoneNumber}`}
              >
                {order.phoneNumber}
              </a>
            </span>
          </div>
          {order.estimatedTime && (
            <div className="flex items-center flex-wrap justify-between mb-1 print:hidden">
              <div className="text-sm font-medium text-gray-900 w-48 flex-shrink-0 print:text-black">
                Tiempo Estimado
              </div>
              <div className="pl-2 print:pl-0 tracking-wide text-gray-600 text-sm break-all max-w-fit">
                {order.estimatedTime}
              </div>
            </div>
          )}
          {order.address && (
            <div className="flex items-center flex-wrap justify-between print:block print:w-full">
              <div className="text-sm font-medium text-gray-900 w-48 flex-shrink-0 print:text-black print:uppercase print:font-bold print:w-full">
                Dirección de entrega
              </div>
              <div className="pl-2 print:pl-0 tracking-wide text-sm text-gray-600 break-all max-w-fit print:text-black print:text-base">
                {order.address}
              </div>
            </div>
          )}
          {order.cancelReason && (
            <div className="flex items-center flex-wrap justify-between print:block print:w-full">
              <div className="text-sm font-medium text-gray-900 w-48 flex-shrink-0 print:text-black print:uppercase print:font-bold print:w-full">
                Motivo Cancelación
              </div>
              <div className="pl-2 print:pl-0 tracking-wide text-sm text-gray-600 break-all max-w-fit print:text-black print:text-base">
                {order.cancelReason}
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="text-lg leading-6 font-medium text-gray-900">
            RESUMEN DEL PEDIDO
          </div>
          {order.items.map((item: OrderItem) => {
            const indexKey =
              item.itemId +
              item.subItems.reduce((prev, subItem) => {
                return `${prev}${subItem.subItemId}${subItem.quantity}`;
              }, "");
            return (
              <div key={indexKey} className="">
                <div className="flex justify-between">
                  <div className="pr-1 break-all max-w-fit text-gray-600 print:text-black">
                    •{" "}
                    <span className="pr-1 text-sm font-bold">
                      {item.quantity}x
                    </span>
                    {item.name}
                  </div>
                  {Number(item.itemPrice) > 0 && (
                    <div>${Number(item.itemAndSubItemsPriceWithQuantity)}</div>
                  )}
                </div>
                {item.subItems.map((subItem: SubItem) => (
                  <div
                    key={subItem.subItemId}
                    className="flex pl-4 justify-between text-gray-700 text-sm print:text-black print:font-semibold break-all max-w-fit"
                  >
                    <div>
                      <span className="pr-1 font-bold">
                        {Number(subItem.quantity * item.quantity)}x
                      </span>
                      {subItem.name}
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
          <div>
            {order.tip !== null && order.tip > 0 && (
              <div className="flex justify-between py-1">
                <div className="font-bold">Propina</div>
                <div>${order.tip}</div>
              </div>
            )}
            {order.deliveryCost ? (
              <div className="flex justify-between py-1">
                <div className="font-bold">Delivery</div>
                <div>${order.deliveryCost}</div>
              </div>
            ) : null}

            {order.cashDiscountAmount && (
              <div className="flex justify-between py-1">
                <div className="font-bold">
                  {order.cashDiscountPercentage}% Descuento Efectivo
                </div>
                <div>- ${order.cashDiscountAmount}</div>
              </div>
            )}
            {order.couponDiscount && (
              <div className="flex justify-between py-1 print:block">
                <div className="font-bold">
                  Cupon de descuento &quot;{order.couponName}&quot;
                </div>
                <div className="print:text-right">
                  - ${order.couponDiscount}
                </div>
              </div>
            )}
          </div>
          <div className="font-semibold pt-2 my-2 border-t border-gray-400 flex justify-between ">
            <div>TOTAL</div>
            <div>${order.price}</div>
          </div>
          <div className="mb-4 font-light text-gray-700 print:text-black print:font-semibold">
            <div>
              <span>
                {order.paymentDTO.paymentMethod === "CASH" && (
                  <span> Pago a realizar en efectivo</span>
                )}
                {order.paymentDTO.paymentMethod === "MERCADO_PAGO" && (
                  <span>
                    Pago realizado con Mercado Pago
                    <span
                      aria-hidden="true"
                      className="hover:opacity-75 underline px-2 cursor-pointer print:hidden print:font-bold print:text-black"
                      onClick={(event) => {
                        event.preventDefault();
                        setPaymentInfoHidden(!paymentInfoHidden);
                      }}
                    >
                      {paymentInfoHidden ? "Ver" : "Ocultar"} Info
                    </span>
                  </span>
                )}
                {order.paymentDTO.paymentMethod === "ADD_TO_ACCOUNT" && (
                  <span>Método de pago: {order.paymentDTO.addToAccountText}</span>                  
                )}
              </span>
            </div>
            {!paymentInfoHidden && (
              <div className="bg-gray-200 p-3 mt-3 rounded-md text-sm print:hidden">
                <div className="text-base mb-1">
                  {order.paymentDTO.payer_last_name
                    ? order.paymentDTO.payer_last_name + ", "
                    : ""}
                  {order.paymentDTO.payer_first_name
                    ? order.paymentDTO.payer_first_name + "- "
                    : ""}
                  {order.paymentDTO.payer_email}
                </div>
                <div>
                  <b>Estado:</b> {order.paymentDTO.status}
                </div>
                <div>
                  <b>Referencia Externa:</b>{" "}
                  {order.paymentDTO.external_reference}
                </div>
                <div>
                  <b>Numero de Operacion:</b>
                  {order.paymentDTO.id}
                </div>
              </div>
            )}
          </div>
          <div className="hidden print:block font-semibold mb-2">
            <div>No valido como factura</div>
            <div>ServiteOnline</div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
