import React, {
  useEffect,
  useState,
  createRef,
  useMemo,
  useContext,
} from "react";
import apiService from "../services/api";
import { SectionsList } from "./SectionsList";
import { ProductList } from "./ProductList";
import { Spinner } from "./Spinner";
import { PageTitle } from "./PageTitle";
import { Section } from "../types";
import { alertService } from "../services/alertService";
import { Button } from "./Button";
import { useParams } from "react-router-dom";
import { StoreContext } from "../services/store.context";

export const MenuItems: React.FC = () => {
  const { sectionId } = useParams<{
    sectionId: string;
  }>();
  const fileInput = createRef<HTMLInputElement>();
  const [sections, setSections] = useState([] as Section[]);
  const [status, setStatus] = useState("idle");
  const [sectionSelectedId, setSectionSelectedId] = useState(Number(sectionId));
  const [importFileStatus, setImportFileStatus] = useState(false);
  const { orderParameters } = useContext(StoreContext);

  const sectionSelected = useMemo(
    () => sections.find((s) => s.id === sectionSelectedId),
    [sections, sectionSelectedId]
  );

  useEffect(() => {
    setSectionSelectedId(Number(sectionId));
  }, [sectionId]);

  const fetchData = async (showSpinner = false) => {
    if (showSpinner) setStatus("loading");
    const response = await apiService.getCall("menu");
    if (!response.error) {
      setSections(response.sections);
      setStatus("resolved");
    } else {
      setStatus("error");
    }
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  const downloadMenu = async () => {
    const response = await apiService.putCall({}, "menu/export", "blob");
    if (!response.error) {
      const blob = new Blob([response]);
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `listado-menu.xlsx`);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } else {
      alertService.error(
        "Hubo un problema en la descarga del archivo, porfavor vuelva a intentarlo nuevamente"
      );
    }
  };

  const handleSubmission = (fileToSend: File) => {
    if (fileToSend.size > 6000000) {
      alertService.error(
        "Ha ocurrido un error, el archivo es demasiado grande, el límite es de 6MB máximo."
      );
      return;
    }
    setImportFileStatus(true);
    apiService
      .putLocalCallFile(fileToSend, "menu/import")
      .then((data) => {
        if (data.error) {
          alertService.error(
            "Ha ocurrido un error, revise el formato del archivo e intente nuevamente. Los formatos aceptados son .xlsx, .xls y .csv."
          );
          return;
        }
        fetchData();
        return alertService.success("Archivo subido con éxito!");
      })
      .catch(() => {
        alertService.error(
          "Ha ocurrido un error, revise el archivo e intente nuevamente"
        );
      })
      .finally(() => setImportFileStatus(false));
  };

  if (status === "loading" || status === "idle") {
    return (
      <div className="p-4">
        <Spinner />
      </div>
    );
  }

  if (status === "error") {
    return null;
  }

  return (
    <>
      <div className="h-full flex flex-col overflow-y-hidden">
        <div className="flex flex-wrap justify-between items-baseline mb-2">
          <PageTitle title="Menú de productos" />
          <input
            ref={fileInput}
            className="hidden"
            accept=".xlsx, .xls, .csv"
            type="file"
            name="file"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleSubmission(event?.target?.files![0])
            }
          />
          {orderParameters.importExportMenuEnabled ? (
            <div className="flex h-auto space-x-4">
              <Button
                text="Importar Menú"
                disabledProp={importFileStatus}
                showSpinner={importFileStatus}
                onClick={() =>
                  fileInput.current ? fileInput.current.click() : null
                }
              />
              <Button text="Exportar Menú" onClick={downloadMenu} />
            </div>
          ) : null}
        </div>
        <div className="grid-cols-1 grid gap-4 md:grid-cols-2 overflow-hidden">
          <div className="bg-white rounded-lg border border-gray-300 p-4 overflow-hidden">
            <SectionsList
              selected={sectionSelectedId}
              sectionList={sections}
              reloadData={fetchData}
              selectSection={setSectionSelectedId}
            />
          </div>
          {sectionSelected && sectionSelected.items && (
            <ProductList reloadData={fetchData} section={sectionSelected} />
          )}
        </div>
      </div>
    </>
  );
};
