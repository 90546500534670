import { Loader } from '@googlemaps/js-api-loader';
import { GoogleMapsDrawingService } from './googleMapsDrawingService';

const loader = new Loader({
  apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  version: 'weekly',
  libraries: ['drawing'],
});

export class GoogleMapsLoadingService {
  static instance: GoogleMapsLoadingService;

  drawingMapService: GoogleMapsDrawingService;

  constructor() {
    this.drawingMapService = new GoogleMapsDrawingService();
  }

  public static getInstance(): GoogleMapsLoadingService {
    if (!GoogleMapsLoadingService.instance) {
      GoogleMapsLoadingService.instance = new GoogleMapsLoadingService();
    }
    return GoogleMapsLoadingService.instance;
  }

  public async getDrawingService(): Promise<GoogleMapsDrawingService> {
    await loader.load();
    return this.drawingMapService;
  }
}
