import React, { useState, useEffect } from "react";
import apiService from "../services/api";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
} from "react-beautiful-dnd";
import { PopUp, PopUphandler } from "./PopUp";
import { ProductElement } from "./ProductElement";
import { Product, Section } from "../types";
import { ProductCreation } from "../pages/ProductEdit";
import { Modal } from "./Modal";

type ProductListProps = {
  section: Section;
  reloadData: () => void;
};

type ProductModal = {
  sectionId: number;
  editMode: boolean;
};

type ProductOrder = Product & { position: number };

export const ProductList = ({ section, reloadData }: ProductListProps) => {
  const [products, setProducts] = useState(section.items);
  const [confirmationPopUp, setConfirmationPopUp] = useState(
    {} as PopUphandler
  );
  const [status, setStatus] = useState("idle");
  const [productModal, showProductModal] = useState({} as ProductModal);

  useEffect(() => {
    setProducts(section.items);
  }, [section]);

  const removeProduct = (index: number) => {
    setConfirmationPopUp({
      handler: async (_event, confirmation: boolean) => {
        if (confirmation) {
          const response = await apiService.deleteCall(
            `menu/item/remove/${products[index].id}`
          );
          if (!response.error) {
            reloadData();
          }
        }
        setConfirmationPopUp({} as PopUphandler);
      },
      message: "Estas seguro que desea eliminar este producto?",
    });
  };

  const changeVisibility = async (id: number, value: boolean) => {
    if (status === "idle") {
      setStatus("updating");
      await apiService.putCall({}, `menu/item/${id}/hide/${value}`);
      reloadData();
      setStatus("idle");
    }
  };

  const productChanged = () => {
    reloadData();
    showProductModal({} as ProductModal);
  };

  const reorder = (list: Product[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const productsOrdered = reorder(
      products,
      result.source.index,
      result.destination.index
    );

    const itemsOrdered: ProductOrder[] = productsOrdered.map(
      (product: Product, index: number) => ({
        ...product,
        position: index,
      })
    );

    apiService.putCall(itemsOrdered, `menu/items/update-order/${section.id}`);

    setProducts(productsOrdered);
  };

  return (
    <>
      {confirmationPopUp.message && (
        <PopUp
          closePopUp={confirmationPopUp.handler}
          message={confirmationPopUp.message}
        />
      )}
      <Modal
        closeModal={() => showProductModal({} as ProductModal)}
        isOpen={!!productModal.sectionId}
        title={productModal.editMode ? "Editar Producto" : "Crear Producto"}
      >
        <ProductCreation
          sectionId={productModal.sectionId}
          productChanged={productChanged}
        />
      </Modal>
      <div className="bg-white rounded-lg border border-gray-300 p-4 flex flex-col overflow-hidden">
        <div className="flex-shrink-0 flex items-start justify-between mb-2">
          <div className="text-xl text-blue-500">
            <div className="flex items-center">
              <div className="font-semibold">{section.name}</div>
              {section.hidden && (
                <div className="ml-2 rounded-sm font-bold text-xs px-2 py-1 bg-red-200 text-red-500">
                  Categoría Oculta
                </div>
              )}
            </div>
            <p className="text-gray-600 text-sm">
              Arrastre los productos para cambiar su orden
            </p>
          </div>
          <button
            className="btn text-xs bg-primary-300 hover:opacity-70 px-2"
            onClick={() =>
              showProductModal({
                sectionId: section.id,
                editMode: false,
              })
            }
          >
            Agregar Producto
          </button>
        </div>
        <div className="flex-1 overflow-y-auto">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided: DroppableProvided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {products.length === 0 && (
                    <div className="mt-2 text-gray-700 text-center border-t border-gray-400 pt-4">
                      Categoría sin productos.
                    </div>
                  )}
                  {products.map((product: Product, index: number) => (
                    <Draggable
                      key={product.id}
                      draggableId={`id-${product.id}`}
                      index={index}
                    >
                      {(providedInner) => (
                        <div
                          ref={providedInner.innerRef}
                          {...providedInner.draggableProps}
                          {...providedInner.dragHandleProps}
                        >
                          <ProductElement
                            product={product}
                            index={index}
                            key={product.id}
                            id={product.id}
                            removeProduct={removeProduct}
                            changeVisibility={changeVisibility}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </>
  );
};
