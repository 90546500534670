import React, { useState } from "react";
import apiService from "../services/api";
import { ImgLoader } from "../components/ImgLoader";
import { alertService } from "../services/alertService";

type ProductProps = {
  sectionId: number;
  productChanged: () => void;
};

export const ProductCreation: React.FC<ProductProps> = ({
  sectionId,
  productChanged,
}) => {
  const [status, setStatus] = useState("resolved");
  const [product, setProduct] = useState({
    name: "",
    description: "",
    imageLink: "",
    price: 0,
    hidden: false,
    itemImage: {
      large: "",
      small: "",
    },
  });

  const saveChanges = async (event: any) => {
    event.preventDefault();
    setStatus("loading");
    try {
      let response;
      response = await apiService.postCall(
        { ...product, sectionId },
        "menu/item/add"
      );
      if (response.error) {
        alertService.error(
          "Ha habido un error, por favor revise la información e intente nuevamente"
        );

        setStatus("resolved");
        return;
      }
      productChanged();
      setStatus("resolved");
    } catch (error) {
      alertService.error("Ha habido un error, por favor intente nuevamente");
      setStatus("resolved");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => saveChanges(event)}>
        <div className="justify-between flex items-center">
          <p className="mb-3 text-gray-700 text-sm">
            Edición de la información básica de un producto.
          </p>
        </div>
        <div className="mb-4">
          <div className="mb-2 flex items-center">
            <input
              disabled={status === "loading" || status === "idle"}
              type="checkbox"
              className="input text-primary-300 mr-3"
              checked={product.hidden}
              onChange={(event) => {
                setProduct({
                  ...product,
                  hidden: event.target.checked,
                });
              }}
            />
            <div>Ocultar Producto del Menu</div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:flex gap-2 md:space-x-4 md:justify-between">
          <div className="flex-grow w-64">
            <div className="mb-4">
              <div className="mb-2">Nombre:</div>
              <input
                disabled={status === "loading" || status === "idle"}
                type="text"
                className="input w-full"
                maxLength={50}
                value={product.name}
                onChange={(event) => {
                  setProduct({ ...product, name: event.target.value });
                }}
              />
            </div>
            <div className="mb-4">
              <div className="mb-2">Precio ($):</div>
              <input
                disabled={status === "loading" || status === "idle"}
                type="number"
                min="0"
                className="input w-full"
                value={product.price}
                onChange={(event) => {
                  setProduct({
                    ...product,
                    price: parseInt(event.target.value, 10),
                  });
                }}
              />
            </div>

            <div className="mb-4">
              <div className="mb-2">Descripcion:</div>
              <textarea
                value={product.description}
                className="input w-full"
                onChange={(event) => {
                  setProduct({
                    ...product,
                    description: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex-grow">
            <ImgLoader
              serverImg={product.itemImage.large}
              pictureUploaded={(url: string) =>
                setProduct({ ...product, imageLink: url })
              }
              pictureDeleted={() =>
                setProduct({
                  ...product,
                  imageLink: "",
                  itemImage: { large: "", small: "" },
                })
              }
            />
          </div>
        </div>
        <div className="flex items-center mt-4">
          <button className="btn bg-primary-300 hover:opacity-70" type="submit">
            Guardar Cambios
          </button>
        </div>
      </form>
    </div>
  );
};
