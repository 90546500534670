import React from "react";
import { Configuration } from "../types";

export const StoreContext = React.createContext({
  orderParameters: {} as Configuration,
  storeName: "",
  updateOrderParameters: () => {},
  areNewsAvailable: false,
  updateNews: (news: any[]) => {},
});
