import React, { useState } from 'react';
import { TAGS } from '../constants';
import { CouponType, COUPON_TYPES, daysOfTheWeek } from '../types';
import { Button } from './Button';

type CouponProps = {
  saveCoupon: (form: CouponType) => void;
  couponProp: CouponType;
  sections: { name: string; id: number }[];
};

export const CouponForm = ({
  saveCoupon,
  sections,
  couponProp = {} as CouponType,
}: CouponProps) => {
  const [form, setForm] = useState(couponProp as CouponType);

  const isformInvalid = !form.name || !form.value;

  return (
    <form className="max-w-screen-lg">
      <div className="flex flex-wrap my-4">
        <div className="pr-3 my-2">
          <label className="block text-sm font-medium text-gray-700">
            Nombre
          </label>
          <input
            className="input"
            type="text"
            value={form.name}
            disabled={!!couponProp.id}
            required
            onChange={(event) => {
              setForm({ ...form, name: event.target.value });
            }}
          />
        </div>
        <div className="pr-3 my-2">
          <label className="block text-sm font-medium text-gray-700">
            Tipo
          </label>
          <select
            className="input w-full"
            value={form.type}
            onChange={(event) => {
              setForm({
                ...form,
                type: event.target.value as 'PERCENTAGE' | 'FIXED',
              });
            }}
          >
            {COUPON_TYPES.map((option) => (
              <option key={option.key} value={option.key}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="pr-3 my-2">
          <label className="block text-sm font-medium text-gray-700">
            {form.type === 'FIXED' ? 'MONTO' : 'PORCENTAJE'} (
            {form.type === 'FIXED' ? '$' : '%'})
          </label>
          <input
            className="input"
            type="text"
            value={form.value}
            onChange={(event) => {
              setForm({ ...form, value: Number(event.target.value) });
            }}
          />
        </div>
        {form.type === 'FIXED' && (
          <div className="pr-3 my-2">
            <label className="block text-sm font-medium text-gray-700">
              Monto mínimo
            </label>
            <input
              className="input"
              type="text"
              required
              value={form.minimumPurchaseAmount}
              onChange={(event) => {
                setForm({
                  ...form,
                  minimumPurchaseAmount: Number(event.target.value),
                });
              }}
            />
          </div>
        )}
        {form.type === 'PERCENTAGE' && (
          <div className="pr-3 my-2">
            <label className="block text-sm font-medium text-gray-700">
              Monto máximo
            </label>
            <input
              className="input"
              type="number"
              required
              value={form.maxDiscountAmount}
              onChange={(event) => {
                setForm({
                  ...form,
                  maxDiscountAmount: Number(event.target.value),
                });
              }}
            />
          </div>
        )}
        <div className="pr-3 my-2">
          <label className="block text-sm font-medium text-gray-700">
            Válido desde
          </label>
          <input
            className="input"
            type="date"
            value={form.validFrom}
            onChange={(event) => {
              setForm({ ...form, validFrom: event.target.value });
            }}
          />
        </div>
        <div className="pr-3 my-2">
          <label className="block text-sm font-medium text-gray-700">
            Válido hasta
          </label>
          <input
            className="input"
            type="date"
            value={form.validTo}
            onChange={(event) => {
              setForm({ ...form, validTo: event.target.value });
            }}
          />
        </div>
        <div className="pr-3 my-2">
          <label className="block text-sm font-medium text-gray-700">
            Categoría
          </label>
          <select
            className="input w-full"
            value={form.sectionId}
            onChange={(event) => {
              setForm({ ...form, sectionId: event.target.value });
            }}
          >
            <option value="">Todas</option>
            {sections.map((section) => (
              <option key={section.id} value={section.id}>
                {section.name}
              </option>
            ))}
          </select>
        </div>
        <div className="pr-3 my-2">
          <label className="block text-sm font-medium text-gray-700">
            Grupo
          </label>
          <select
            className="input w-full"
            value={form.posTag}
            onChange={(event) => {
              setForm({ ...form, posTag: event.target.value });
            }}
          >
            {TAGS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="pr-3 my-2">
          <label className="block text-sm font-medium text-gray-700">
            Día de la semana
          </label>
          <select
            className="input w-full"
            value={form.dayOfWeek}
            onChange={(event) => {
              setForm({ ...form, dayOfWeek: Number(event.target.value) });
            }}
          >
            {daysOfTheWeek.map((option) => (
              <option key={option.label} value={option.key}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="pr-3 my-2">
          <label className="block text-sm font-medium text-gray-700">
            Máxima cantidad de usos
          </label>
          <input
            className="input"
            type="NUMBER"
            required
            value={form.maxUsages}
            onChange={(event) => {
              setForm({ ...form, maxUsages: Number(event.target.value) });
            }}
          />
        </div>
      </div>
      <div>
        <Button
          disabledProp={isformInvalid}
          onClick={() => saveCoupon(form)}
          text="GUARDAR CUPÓN"
        />
      </div>
    </form>
  );
};
