import React, { useEffect, useState } from "react";
import apiService from "../services/api";
import { Spinner } from "../components/Spinner";
import { alertService } from "../services/alertService";
import { ImgLoader } from "../components/ImgLoader";
import { Button } from "../components/Button";
import { PageTitle } from "../components/PageTitle";
import { Tab } from "@headlessui/react";

type LocationInfo = {
  address: string;
  city: string;
  averageServiceTime: number;
  description: string;
  displayName: string;
  displayPointOfContact: boolean;
  email: string;
  mainImage: string;
  openHours: string;
  paymentMethods: string;
  phoneNumber: string;
  pointOfContact: string;
  socialNetworks: { [key: string]: string };
  web: string;
};

const Info = () => {
  const [locationInfo, setLocationInfo] = useState({} as LocationInfo);
  const [status, setStatus] = useState("idle");
  const [pictureUrl, setPictureUrl] = useState("");
  const pictureUploaded = (url: string) => {
    setPictureUrl(url);
  };

  const tabs = ["INFORMACIÓN", "CONTACTO"];

  const fetchData = async () => {
    const response: LocationInfo = await apiService.getCall("store/details");
    setLocationInfo(response);
    setPictureUrl(response.mainImage);
    setStatus("resolved");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const saveData = async () => {
    setStatus("loading");
    const response = await apiService.putCall(
      { ...locationInfo, mainImage: pictureUrl },
      "store/details/update"
    );
    if (!response.error) {
      fetchData();
    } else {
      alertService.error(
        "Ha ocurrido un problema vuelva a intentarlo nuevamente",
        { autoClose: true, keepAfterRouteChange: false }
      );
      setStatus("resolved");
    }
  };

  const classNames = (...classes: string[]) =>
    classes.filter(Boolean).join(" ");

  if (status === "loading" || status === "idle") {
    return (
      <div className="p-4">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <PageTitle title="Información del comercio" />
      <div>
        <form className="section-border p-4 my-8 relative">
          <Tab.Group>
            <Tab.List className="w-full md:w-1/2 lg:w-1/3 grid grid-cols-2 gap-4 border-b border-gray-300">
              {tabs.map((tab) => (
                <Tab
                  key={tab}
                  className={({ selected }) =>
                    classNames(
                      "w-full py-2 text-sm leading-5 font-medium text-gray-600",
                      "focus:outline-none",
                      selected
                        ? "text-primary-300 border-b-2 border-primary-200"
                        : " hover:opacity-50"
                    )
                  }
                >
                  {tab}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 my-8">
                  <div>
                    <div className="mb-2 w-auto">
                      <label className="block text-sm font-medium text-gray-700">
                        Nombre
                      </label>
                      <input
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setLocationInfo({
                            ...locationInfo,
                            displayName: event.target.value,
                          })
                        }
                        className="input w-full"
                        type="text"
                        value={locationInfo.displayName}
                      />
                    </div>
                    <div className="mb-2 w-auto">
                      <label className="block text-sm font-medium text-gray-700">
                        Descripción
                      </label>
                      <textarea
                        rows={3}
                        onChange={(
                          event: React.ChangeEvent<HTMLTextAreaElement>
                        ) =>
                          setLocationInfo({
                            ...locationInfo,
                            description: event.target.value,
                          })
                        }
                        className="input w-full"
                        value={locationInfo.description}
                      />
                    </div>
                    <div className="mb-2 w-auto">
                      <label className="block text-sm font-medium text-gray-700">
                        Horarios
                      </label>
                      <textarea
                        rows={3}
                        onChange={(
                          event: React.ChangeEvent<HTMLTextAreaElement>
                        ) =>
                          setLocationInfo({
                            ...locationInfo,
                            openHours: event.target.value,
                          })
                        }
                        className="input w-full"
                        value={locationInfo.openHours}
                      />
                    </div>
                    <div className="mb-2 w-auto">
                      <label className="block text-sm font-medium text-gray-700">
                        Métodos de pago
                      </label>
                      <input
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setLocationInfo({
                            ...locationInfo,
                            paymentMethods: event.target.value,
                          })
                        }
                        className="input w-full"
                        type="text"
                        value={locationInfo.paymentMethods}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Imagen promocional
                    </label>
                    <div className="w-48">
                      <ImgLoader
                        serverImg={locationInfo.mainImage}
                        pictureUploaded={pictureUploaded}
                        pictureDeleted={() =>
                          setLocationInfo({
                            ...locationInfo,
                            mainImage: "",
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 my-8">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setLocationInfo({
                          ...locationInfo,
                          email: event.target.value,
                        })
                      }
                      className="input w-full"
                      type="email"
                      value={locationInfo.email}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Teléfono
                    </label>
                    <input
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setLocationInfo({
                          ...locationInfo,
                          phoneNumber: event.target.value,
                        })
                      }
                      className="input w-full"
                      type="text"
                      value={locationInfo.phoneNumber}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Dirección
                    </label>
                    <input
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setLocationInfo({
                          ...locationInfo,
                          address: event.target.value,
                        })
                      }
                      className="input w-full"
                      type="text"
                      value={locationInfo.address}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Ciudad
                    </label>
                    <input
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setLocationInfo({
                          ...locationInfo,
                          city: event.target.value,
                        })
                      }
                      className="input w-full"
                      type="text"
                      value={locationInfo.city}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      WhatsApp
                    </label>
                    <input
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setLocationInfo({
                          ...locationInfo,
                          pointOfContact: event.target.value,
                        })
                      }
                      className="input w-full"
                      type="text"
                      value={locationInfo.pointOfContact}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Web del comercio
                    </label>
                    <input
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setLocationInfo({
                          ...locationInfo,
                          web: event.target.value,
                        })
                      }
                      className="input w-full"
                      type="text"
                      value={locationInfo.web}
                    />
                  </div>
                  <div>
                    <label className="block font-semibold pr-4">
                      Facebook del comercio
                    </label>
                    <p className="text-gray-600 mb-2 text-sm">
                      Introduzca el enlace completo
                    </p>
                    <input
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setLocationInfo({
                          ...locationInfo,
                          socialNetworks: {
                            ...locationInfo.socialNetworks,
                            facebook: event.target.value,
                          },
                        })
                      }
                      className="input w-full"
                      type="text"
                      value={locationInfo.socialNetworks.facebook}
                    />
                  </div>
                  <div>
                    <label className="block font-semibold pr-4">
                      Instagram del comercio
                    </label>
                    <p className="text-gray-600 mb-2 text-sm">
                      Introduzca el enlace completo
                    </p>
                    <input
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setLocationInfo({
                          ...locationInfo,
                          socialNetworks: {
                            ...locationInfo.socialNetworks,
                            instagram: event.target.value,
                          },
                        })
                      }
                      className="input w-full"
                      type="text"
                      value={locationInfo.socialNetworks.instagram}
                    />
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          <Button text="Guardar Cambios" onClick={saveData} />
        </form>
      </div>
    </>
  );
};

export default Info;
