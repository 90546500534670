import React from "react";
import { Section } from "../types";
import {
  EyeIcon,
  EyeOffIcon,
  TrashIcon,
  ChevronRightIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";

export interface CardProps {
  section: Section;
  selected: boolean;
  index: number;
  handleClick: () => void;
  removeSection: (event: any, index: number) => void;
  editSection: (event: any, section: Section) => void;
  changeVisibility: (id: number, value: boolean) => void;
}

export const SectionElement = ({
  section,
  selected,
  index,
  handleClick,
  removeSection,
  editSection,
  changeVisibility,
}: CardProps) => {
  return (
    <div
      aria-hidden="true"
      onClick={(event: any) => {
        if (event.currentTarget !== event.target) return;
        handleClick();
      }}
      className={`w-full flex p-2 border rounded-lg cursor-pointer justify-between my-1 ${
        selected
          ? "border-blue-500 bg-blue-500 text-white"
          : "section-border hover:opacity-75 hover:bg-gray-300"
      }`}
    >
      <div className="flex items-center min-w-0">
        <svg
          className="mr-1 flex-shrink-0 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
        </svg>
        <div
          aria-hidden="true"
          onClick={handleClick}
          className="pl-1 overflow-ellipsis overflow-x-hidden whitespace-nowrap mr-2"
        >
          {section.name}
        </div>
      </div>
      <div className="flex items-center self-end">
        {section.hidden ? (
          <button
            title="Habilitar Sección"
            onClick={() => changeVisibility(section.id, false)}
          >
            <EyeOffIcon
              className={`cursor-pointer hover:opacity-75 mr-2 h-6  ${
                selected ? "text-white" : "text-red-600"
              }`}
            />
          </button>
        ) : (
          <button
            title="Deshabilitar Sección"
            onClick={() => changeVisibility(section.id, true)}
          >
            <EyeIcon className="cursor-pointer hover:opacity-75 mr-2 h-6" />
          </button>
        )}
        <button
          title="Editar Sección"
          onClick={(event) => editSection(event, section)}
        >
          <PencilAltIcon className="h-6 cursor-pointer hover:opacity-75 mr-2" />
        </button>
        <button
          onClick={(event) => removeSection(event, index)}
          className="cursor-pointer hover:opacity-75 mr-2 fill-current"
          title="Eliminar Sección"
        >
          <TrashIcon className="h-6" />
        </button>
        <button>
          <ChevronRightIcon className="text-white h-6" />
        </button>
      </div>
    </div>
  );
};
