import React from 'react';

export const Tooltip: React.FC<{ text: string }> = ({ children, text }) => {
  return (
    <div className="relative z-50">
      <p className="tooltip">
        <span className="tooltip-text bg-black text-white text-xs rounded py-1 px-4 right-0 bottom-full">
          {text}
        </span>
        <svg
          className="tooltip-pointer text-black h-2 right-0 mr-3 top-full"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
        >
          <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
        </svg>
        {children}
      </p>
    </div>
  );
};
