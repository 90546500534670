export class FetchService {
  private apiBasePath = process.env.REACT_APP_API_PATH;

  async getCall(api: string, isNotJson = false): Promise<any> {
    try {
      const headers = new Headers();
      headers.append(
        "Authorization",
        `bearer ${localStorage.getItem("tokenServiteOnline")}`
      );
      let data = await fetch(`${this.apiBasePath}${api}`, {
        headers,
      });
      if (data.status === 403) {
        throw new Error(
          "No tiene permisos para realizar esta operación, consulte con tu adminsitrador."
        );
      }
      if (!isNotJson) {
        const jsonData = await data.json();
        if (jsonData.error) {
          throw new Error(
            "Ha habido un error, porfavor revise la información e intente nuevamente"
          );
        }
        return jsonData;
      }
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async noAuthPostCall(body: any, api: string) {
    try {
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      const response = await fetch(`${this.apiBasePath}${api}`, {
        headers: myHeaders,
        method: "POST",
        body: JSON.stringify(body),
      });
      const jsonResponse = await response.json();
      return { ok: response.ok, content: jsonResponse };
    } catch (err) {
      return { error: err };
    }
  }

  async postCall(body: any, api: string) {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("tokenServiteOnline")}`
      );

      const response = await fetch(`${this.apiBasePath}${api}`, {
        headers: myHeaders,
        method: "POST",
        body: JSON.stringify(body),
      });
      const data = await response.json();

      return data;
    } catch (err) {
      return { error: err };
    }
  }

  async deleteCall(api: string) {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("tokenServiteOnline")}`
      );

      const response = await fetch(`${this.apiBasePath}${api}`, {
        headers: myHeaders,
        method: "DELETE",
      });
      const data = await response.json();

      return data;
    } catch (err) {
      return { error: err };
    }
  }

  async putLocalCallFile(body: File, url: string) {
    try {
      const myHeaders = new Headers();
      const formData = new FormData();
      formData.append("file", body);
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("tokenServiteOnline")}`
      );
      const response = await fetch(this.apiBasePath + url, {
        headers: myHeaders,
        method: "PUT",
        body: formData,
      });
      const data = await response.json();
      return data;
    } catch (err) {
      return { error: err };
    }
  }

  static async putCallFile(bodyFile: File, url: string) {
    try {
      const response = await fetch(url, {
        method: "PUT",
        body: bodyFile,
      });
      const data = await response.json();
      return data;
    } catch (err) {
      return { error: err };
    }
  }

  async putCall(body: any, api: string, type = "json") {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("tokenServiteOnline")}`
      );

      const response = await fetch(`${this.apiBasePath}${api}`, {
        headers: myHeaders,
        method: "PUT",
        body: JSON.stringify(body),
      });

      if (type === "blob") {
        const data = await response.blob();
        return data;
      }
      const data = await response.json();
      return data;
    } catch (err) {
      return { error: err };
    }
  }

  async patchCall(
    body: Record<string, unknown> | Record<string, unknown>[],
    api: string
  ) {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("tokenServiteOnline")}`
      );

      const response = await fetch(`${this.apiBasePath}${api}`, {
        headers: myHeaders,
        method: "PATCH",
        body: JSON.stringify(body),
      });
      const data = await response.json();

      return data;
    } catch (err) {
      return { error: err };
    }
  }

  async postFormCall(
    form: { [key: string]: string },
    api: string,
    auth: { username: string; password: string }
  ) {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Basic ${btoa(`${auth.username}:${auth.password}`)}`
      );

      const formPostData = Object.keys(form).reduce((formData, key) => {
        formData.append(key, form[key]);
        return formData;
      }, new FormData());

      const options = {
        headers: myHeaders,
        method: "POST",
        body: formPostData,
      };

      const response = await fetch(`${this.apiBasePath}${api}`, options);
      const data = await response.json();
      return data;
    } catch (err) {
      return { error: err };
    }
  }
}

const apiService = new FetchService();
export default apiService;
